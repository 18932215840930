import axios from "axios";

const { APIURL } = require("global");

// const getportfolioList = async() =>{
//     const listApi = APIURL + "portfolio/list";
//     return await fetch (listApi);
// }

const getportfolioDataByKey = async (data) => {
  console.log(data);
  try {
    const URL = APIURL + "portfolio/getPortfolioDataByKey";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const getActivePortfolioList = async (list) => {
  try {
    const URL = APIURL + `portfolio/activeList`;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export { getportfolioDataByKey, getActivePortfolioList };
