import React from "react";
import { Col, Row } from "react-bootstrap";
import "./About.css";
import { NavLink, Link } from "react-router-dom";
import Banner from "../Banner/Banner";

function About() {
  return (
    <>
      <section className="pg-about">
        {/* Banner */}
        {/* <Banner /> */}

        {/* About Content */}
        <section className="ev-about-sec">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col lg={12}>
                  <div className="grid-50 about-left_sec">
                    <div className="inner_wrapper">
                      <p>
                        Welcome to <b>Insurance Valuator</b>, your trusted
                        partner in financial planning. We understand that
                        securing your financial future is critical, which is why
                        we provide a wide range of services tailored to your
                        specific needs. From health insurance to life insurance,
                        general insurance to mutual funds, and motor insurance
                        to credit products, we are here to provide expert
                        guidance every step of the way.
                        <br />
                        <br />
                        With a team of experienced professionals, we strive to
                        ensure that you have access to the best financial
                        products and solutions available in the market. Whether
                        you're planning for your retirement, safeguarding your
                        family's future, or investing for growth, we are
                        committed to helping you achieve your financial goals.
                        <br />
                        <br />
                        At Insurance Valuator, we prioritize transparency,
                        integrity, and personalized service. Our goal is to
                        build long-term relationships with our clients based on
                        trust and mutual respect. We take the time to understand
                        your unique circumstances and objectives, and then
                        develop customized strategies to help you succeed.
                        <br />
                        <br />
                        With Insurance Valuator, you can rest assured knowing
                        that your financial well-being is in good hands. Contact
                        us today to learn more about how we can help you plan
                        for a secure and prosperous future.
                      </p>
                    </div>
                  </div>
                </Col>
                {/* <Col lg={6}>
                  <div className="grid-50 about-right_sec">
                    <div className="about_img">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/W_Lc_HHJ5kw"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    </div>
                  </div>
                </Col> */}
              </Row>
              <div className="flexi"></div>
            </div>
          </div>
        </section>
        {/* Philosophy */}
        {/* <section className="ev-philosophy_sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h3>Our Philosophy</h3>
                <p>
                  We are the founder's first ecosystem with a vast experience
                  with EdTech startups and education entrepreneurs. Our entire
                  focus lays on providing the best education sector focused
                  insights, experience, on ground relatability, market access
                  opportunities, and a personalized support structure to the
                  budding EdTech companies.
                </p>
                <div className="bottom_features">
                  <ul className="p-0">
                    <li>
                      <NavLink>
                        <img src="images/About/WeInspire.svg" />
                        <h5>We Inspire:</h5>
                        <p>
                          Create lasting innovative solutions for education
                          problems
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img src="images/About/WeNurture.svg" />
                        <h5>We Nurture:</h5>
                        <p>
                          Enabling environment for creating a minimum viable
                          product (MVP)
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img src="images/About/WeStabilize.svg" />
                        <h5>We Stabilize:</h5>
                        <p>
                          Help Entrepreneurs find the right
                          Product-Market-Channel fit
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img src="images/About/WeScale.svg" />
                        <h5>We Scale:</h5>
                        <p>
                          Enable ventures identify Product-Market-Channel to
                          grow users
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img src="images/About/WeMonetize.svg" />
                        <h5>We Monetize:</h5>
                        <p>
                          Help the venture stand on its own feet by establishing
                          profitability
                        </p>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* Work with us */}
        {/* <section className="ev-work-withUs_sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h2 className="main_head">Work With Us</h2>
                <p>We are looking for you, change maker!</p>
                <p className="para">
                  It’s difference that makes us great. Join a team where
                  diversity of ideas are welcomed, and we are all looking to get
                  better, one moment at a time.
                </p>
                <Link to="/career">
                  <button className="green_btn">
                    Careers
                    <img src="images/Home/white_arrow.svg" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section> */}
      </section>
    </>
  );
}

export default About;
