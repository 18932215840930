import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  getUsersList,
  deleteUser,
  activateUser,
  deactivateUser,
} from "services/user.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "components/admin/Admin-Pagination/Pagination";

export const UserListView = ({ users }) => {
  const [Users, SetUsers] = useState(users);
  const [paginationData, setPaginationData] = useState([]);
  const navigate = useNavigate();

  const listUser = () => {
    getUsersList()
      .then((response) => response.json())

      .then((data) => {
        SetUsers(data.Result);
      });
  };




  const delUser = async (UserID) => {
    let response = await deleteUser(UserID);
    listUser();
    const notify = () => toast(response.data.Message);
    notify();
  };

  const activate = async (UserID) => {
    let response = await activateUser(UserID);
    console.log("activeee", response);
    listUser();
    const notify = () => toast(response.data.Message);
    notify();
  };

  const deactivate = async (UserID) => {
    let response = await deactivateUser(UserID);
    console.log("deactiveee", response);
    listUser();
    const notify = () => toast(response.data.Message);
    notify();
  };

  useEffect(() => {
    SetUsers(users);
  }, [users]);

  const handleNavigate = () => {
    navigate("/admin/adduser")
  }

  // console.log(paginationUsers, "PaginateUser")

  return (
    <>
      <div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
        <div className="mb-0 px-4 py-3 border-0 bg-slate-300">
          <div className="flex flex-wrap items-center">
            <div className="w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-black">
                List of Users
              </h3>
            </div>
            <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
              <div className=" mt-32 sm:mt-0">
                <button
                  className="bg-slate-950	active:bg-lightBlue-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleNavigate}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr className="bg-blue-950">
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Username
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Name
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Email
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Status
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {paginationData?.map((user, index) => {
                return (
                  <tr key={index}>
                    <th className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {user.Username}
                    </th>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {user.Name}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {user.Email}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {user.CStatus}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4 space-x-4">
                      {user.CStatus === "Active" && (
                        <>
                          <Link to={"/admin/editUser/" + user.UserID}>
                            <i class="fas fa-edit"></i>
                          </Link>
                        </>
                      )}

                      {(user.CStatus === "Active" ||
                        user.CStatus === "Deactive") && (
                        <>
                          <button onClick={() => delUser(user.UserID)}>
                            <i class="fas fa-trash text-red-600"></i>
                          </button>
                        </>
                      )}

                      {(user.CStatus === "Deactive" ||
                        user.CStatus === "Delete") && (
                        <>
                          <button onClick={() => activate(user.UserID)}>
                            <i class="fas fa-check-circle"></i>
                          </button>
                        </>
                      )}

                      {(user.CStatus === "Active" ||
                        user.CStatus === "Delete") && (
                        <>
                          <button onClick={() => deactivate(user.UserID)}>
                            <i class="fas fa-ban text-red-600"></i>
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination UserData={{Users, SetUsers, paginationData, setPaginationData}} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
