import React, { useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import DetailedInfo from "../Dashboard-Tabs/DetailedInfo";
import BusinessPlan from "../Dashboard-Tabs/BusinessPlan";
import EvaluationReport from "../Dashboard-Tabs/EvaluationReport";
import DashboardProfile from "../Dashboard-Profile/DashboardProfile";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import ScrollToTop from "components/front/ScrollToTop";
import { createApplication } from "services/application.service";

function DashboardForm() {
  const user = JSON.parse(localStorage.getItem("loggedUser"));
  // const [execution, setExecution] = useState([
  //   { aggregate: "", lastYear: "", lastMonth: "", likelyHappen: "" },
  // ]);
  const [subscriptionData, setSubscriptionData] = useState();
  const [execution, setExecution] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    createby: user.userId,
    userID: user.userId,
  });
  const [rows, setRows] = useState([
    {
      createby: user.userId,
    },
  ]);
  const [key, setKey] = useState("detail");
  const handleTabs = (e, tab) => {
    if (tab === "detail") {
      setKey("profile");
      let obj = dashboardData;
      obj.formStatus = "detailsSubmit";
      obj.applicationMember = rows;
      createApplication(obj).then((response) => {
        console.log(response);
      });
    }
  };

  const applicationDetailSubmit = () => {
    createApplication(dashboardData).then((response) => {
      console.log(response);
    });
  };
  const handleSecondTabs = (e, tab) => {
    console.log(tab);
    if (tab === "profile") setKey("contact");
  };

  // useEffect(() => {
  //   setKey(state.key);
  // }, [state.key]);
  return (
    <>
      <section className="bg-[#f3f4fb] py-[50px]">
        <Container className="">
          <DashboardProfile />
          <Row>
            <Col lg={12}>
              <div className="dashboard-tabs">
                <Tabs
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  id="uncontrolled-tab-example"
                  className="border-0"
                >
                  <Tab
                    eventKey="detail"
                    title="Detailed Information"
                    className="tab_container"
                  >
                    <DetailedInfo
                      formData={{
                        handleTabs,
                        dashboardData,
                        setDashboardData,
                        rows,
                        setRows,
                        execution,
                        setExecution,
                        subscriptionData,
                        setSubscriptionData,
                      }}
                    />
                  </Tab>
                  <Tab
                    eventKey="profile"
                    title="Business Plan"
                    className="tab_container"
                  >
                    <BusinessPlan
                      formData={{
                        handleSecondTabs,
                        dashboardData,
                        setDashboardData,
                        rows,
                        setRows,
                        execution,
                        setExecution,
                        subscriptionData,
                        setSubscriptionData,
                      }}
                    />
                  </Tab>
                  <Tab
                    eventKey="contact"
                    title="Evaluation Report"
                    className="tab_container"
                  >
                    <EvaluationReport
                      formData={{
                        dashboardData,
                        setDashboardData,
                        rows,
                        setRows,
                        execution,
                        setExecution,
                        subscriptionData,
                        setSubscriptionData,
                      }}
                    />
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default DashboardForm;
