import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getMyAccountDetails, userUpdate } from "services/user.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function DashboardProfile() {
  const [isReadonly, setIsReadonly] = useState(true);
  console.log(setIsReadonly);
  const [myAccountDetails, setMyAccountDetails] = useState([]);
  const user = JSON.parse(localStorage.getItem("loggedUser"));
  const userDetails = {
    userID: user.userId,
  };

  useEffect(() => {
    getMyAccountDetails(userDetails).then((response) => {
      console.log(response)
      setMyAccountDetails(response.data);
    });
  }, []);

  const handleEditProfile = (e) => {
    e.preventDefault();
    let obj = myAccountDetails;
    delete obj.Applications;
    setIsReadonly((prevState) => !prevState);
    if(isReadonly === false){
      obj.createby = userDetails.userID;
      userUpdate(obj)
          .then((response) => response.json())
          .then((data) => {
        if(data.Status === "Success" && !data.Error){
          toast.success(data.Message);
          
        }
        else{
          toast.error(data.Message);
        }
      })
    }
    else {
      console.log('im else method');
    }
  };

  const handleChange = (e) => {
    setMyAccountDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <>
      <div className="grid-25 right_sec">
        <div className="flip-card-3D-wrapper">
          <div id="flip-card">
            <div className="flip-card-front">
              {/* <h3 className="text-xl pt-[5px]">
                <span className="profile_title"></span> Profile
              </h3> */}
              <form className="preview_card mx-4" onSubmit={handleEditProfile}>
                <Row className="justify-center items-center">
                  <Col md={2}>
                    <div className="avatar-upload">
                      <div
                        className="avatar-edit"
                        style={{
                          display: `${isReadonly ? "none" : "block"} `,
                        }}
                      >
                        <input
                          type="file"
                          id="imageUpload"
                          accept=".png, .jpg, .jpeg"
                        />
                        <label></label>
                      </div>
                      <div className="avatar-preview">
                        <div
                          id="imagePreview"
                          style={{
                            backgroundImage:
                              "url(http://i.pravatar.cc/500?img=7)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form_group">
                      {/* <label>E-mail</label> */}
                      <input
                        type="email"
                        readOnly={isReadonly}
                        placeholder="Email Address"
                        name="email"
                        onChange={handleChange}
                        value={myAccountDetails?.email}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form_group">
                      {/* <label>Mobile No.</label> */}
                      <input
                        type="number"
                        name="contact"
                        readOnly={isReadonly}
                        placeholder="Mobile Number"
                        onChange={handleChange}
                        value={myAccountDetails?.contact}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <button id="flip-card-btn-turn-to-back" type="submit">
                      {!isReadonly ? "Submit" : "Edit Profile"}
                    </button>
                  </Col>
                </Row>

                {/* <hr /> */}

                {/* <button type="submit">submit</button> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
}

export default DashboardProfile;
