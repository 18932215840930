import axios from "axios";

/**
 *this api use for to show parner-content
 */

const { APIURL } = require("global");

const getparnerList = async () => {
  const listApi = APIURL + "partner/activeList";
  
  return await fetch(listApi);
};

const GetPartnerlList = async (list) => {
  try {
    const URL = APIURL + `partner/list`;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
const GetPartnerlListByID = async (id) => {
  try {
    const URL = APIURL + `partner/` + id;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
const CreatePartner = async (id) => {
  try {
    const URL = APIURL + "partner/create";
    return await axios.post(`${URL}`, id);
  } catch (error) {
    return error.response;
  }
};
const UpdatePartner = async (data) => {
  try {
    const URL = APIURL + "partner/update";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};
const ActivatePartner = async (data) => {
  try {
    const URL = APIURL + "partner/activate";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};
const DeactivatePartner = async (data) => {
  try {
    const URL = APIURL + "partner/deactivate";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};
const DeletePartner = async (data) => {
  try {
    const URL = APIURL + "partner/delete";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

export {
  getparnerList,
  GetPartnerlList,
  GetPartnerlListByID,
  CreatePartner,
  UpdatePartner,
  ActivatePartner,
  DeactivatePartner,
  DeletePartner,
};
