import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  getActiveBlogCategory,
  blogDataByBlogCategoryID,
} from "services/blog.service";

import "./Media.css";
import { IMAGEURL } from "global";

function Media() {
  // const { state } = useLocation();
  // const [key, setKey] = useState(state.key);
  // const [blogCategoryData, setBlogCategoryData] = useState([]);
  // const [blogData, setBlogData] = useState([]);
  // console.log(state.key);

  // useEffect(() => {
  //   if (state.key === "pr" || state.key === undefined) {
  //     getActiveBlogCategory().then((res) => {
  //       setBlogCategoryData(res.data.Result);
  //       setKey(res.data.Result[0].BlogCategoryID);
  //       state.key = res.data.Result[0].BlogCategoryID;
  //     });
  //   } else {
  //     setKey(state.key);
  //     getActiveBlogCategory().then((res) => {
  //       setBlogCategoryData(res.data.Result);
  //     });
  //   }
  // }, [state.key]);

  // useEffect(() => {
  //   blogDataByBlogCategoryID(state.key).then((resp) => {
  //     setBlogData(resp.data.Result);
  //   });
  // }, [state.key]);

  // const handleChange = (k, e) => {
  //   setKey(k);
  //   blogDataByBlogCategoryID(k).then((resp) => {
  //     setBlogData(resp.data.Result);
  //   });
  // };
  return (
    <>
      <section className="pg-media">
        {/* banner */}
        <section className="ev-Banner_sec">
          <div className="d-flex flex-column justify-content-center">
            <div className="grid-50 coverup">
              <h1>Media</h1>
            </div>
          </div>
        </section>
        <Container>
          <section className="media-container">
            <Tabs
              // activeKey={key}
              // onSelect={(k, e) => {
              //   handleChange(k, e);
              // }}
              className="media-tabs"
              fill
            >
              {/* {blogCategoryData?.length > 0
                ? blogCategoryData?.map((item, index) => {
                    return (
                      <Tab
                        eventKey={item.BlogCategoryID}
                        title={item.Title}
                        key={index}
                        id={item.BlogCategoryID}
                      >
                        <div className="media-tabs-content">
                          <div className="bottom_blogs">
                            {blogData?.length > 0
                              ? blogData?.map((items) => (
                                  <Row>
                                    <Col lg={4}>
                                      <div className="inner_wrapper">
                                        <img src={`${IMAGEURL}${items.Image}`} alt="" />
                                        <span>
                                          {items.CreateDate.slice(0, 10)}
                                        </span>
                                        <h3>{items.Title}</h3>
                                        <p>{items.ShortDescription}</p>
                                      </div>
                                    </Col>
                                  </Row>
                                ))
                              : "No Blog Found"}
                          </div>
                        </div>
                      </Tab>
                    );
                  })
                : "No Data Found"} */}

              <Tab
              // eventKey={item.BlogCategoryID}
              // title={item.Title}
              // key={index}
              // id={item.BlogCategoryID}
              >
                <div className="media-tabs-content">
                  <div className="bottom_blogs">
                    <Row>
                      <Col lg={4}>
                        <div className="inner_wrapper">
                          <img
                            src="https://d28c6jni2fmamz.cloudfront.net/footer_iso_1fc14f20c4.svg"
                            alt=""
                          />
                          <span>26-Jan-2024</span>
                          <h3>Testing</h3>
                          <p>ShortDescription</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab>
            </Tabs>
            <div className="media-tabs-content">
              <div className="bottom_blogs">
                <Row>
                  <Col lg={4}>
                    <div className="inner_wrapper">
                      <img
                        src="https://d28c6jni2fmamz.cloudfront.net/footer_iso_1fc14f20c4.svg"
                        alt=""
                      />
                      <span>26-Jan-2024</span>
                      <h3>Testing</h3>
                      <p>ShortDescription</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        </Container>
      </section>
    </>
  );
}

export default Media;
