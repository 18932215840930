// import React from "react";
import React, { useEffect, useState } from "react";
import { getconfigurationActiveList } from "services/configuration.service";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import amarfinanciallogo from "../Header/image/AmarFinancelogo.png";

const Footer = () => {
  const [configure, setConfigure] = useState([]);
  useEffect(() => {
    // console.log("Hiiiii");
    getconfigurationActiveList()
      .then((response) => response.json())
      .then((data) => {
        setConfigure(data.Result);
      });
  }, []);

  return (
    <>
      <section className="pg-footer">
        <footer className="ev-footer_sec">
          <Container>
            <div className="main_wrap">
              <Row>
                <Col lg={4}>
                  <div className="first">
                    <div className="inner_wrapper">
                      {/* <img
                        src="../images/Logo.svg"
                        className="footer_logo"
                        alt=""
                      /> */}
                      <img
                        // src="../images/header/AmarFinancelogo.png"
                        src={amarfinanciallogo}
                        className="footer_logo"
                        alt=""
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="second">
                    <div className="inner_wrapper">
                      <h3>Quick Links</h3>
                      <ul>
                        <div className="left">
                          <li>
                            <NavLink to="/">Home</NavLink>
                          </li>
                          <li>
                            <NavLink to="about">Abouts Us</NavLink>
                          </li>{" "}
                          <li>
                            <NavLink to="admin/login">Login</NavLink>
                          </li>
                          {/* <li>
                            <NavLink to="marathon">Our Offerings</NavLink>
                          </li> */}
                          {/* <li>
                            <NavLink to="media">Media</NavLink>
                          </li> */}
                        </div>
                        <div className="right">
                          <li>
                            <NavLink to="privacyPolicy">Privacy Policy</NavLink>
                          </li>
                          {/* <li>
                            <NavLink to="portfolios">Portfolios</NavLink>
                          </li> */}
                          <li>
                            <NavLink to="contactUs">Contact Us</NavLink>
                          </li>{" "}
                        </div>
                      </ul>
                      <p>
                        <a
                          // href="https://www.facebook.com/edupreneurvillage/"
                          href="https://www.facebook.com/"
                          target="blank"
                        >
                          <img src="../images/Footer/facebook.svg" alt="" />
                        </a>

                        <a
                          // href="https://twitter.com/EdupreneurV"
                          href="https://twitter.com"
                          target="blank"
                        >
                          <img src="../images/Footer/twitter.svg" alt="" />
                        </a>
                        <a
                          // href="https://www.youtube.com/channel/UCxnkg-FVIEtbTiDXyQ9cMgA"
                          href="https://www.youtube.com"
                          target="blank"
                        >
                          <img src="../images/Footer/youtube.svg" alt="" />
                        </a>
                        <a
                          // href="https://www.linkedin.com/company/edupreneurvillage"
                          href="https://www.linkedin.com/"
                          target="blank"
                        >
                          <img src="../images/Footer/linkedin.svg" alt="" />
                        </a>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="third">
                    <div className="inner_wrapper">
                      <h3>Contact Us</h3>
                      {/* 
                      {configure.length > 0
                        ? configure.map((configures, index) => (
                            <ul key={index}>
                              <li>
                                <NavLink>
                                  <img
                                    src="../images/Footer/location.svg"
                                    alt=""
                                  />
                                  {configures.Address}
                                </NavLink>
                              </li>
                              <li>
                                <NavLink>
                                  <img src="../images/Footer/call.svg" alt="" />
                                  {configures.Phone}
                                </NavLink>
                              </li>
                              <li>
                                <NavLink>
                                  <img
                                    src="../images/Footer/email.svg"
                                    alt=""
                                  />
                                  {configures.EmailID}
                                </NavLink>
                              </li>
                            </ul>
                          ))
                        : ""} */}
                      <ul>
                        <li>
                          <NavLink>
                            <img src="../images/Footer/location.svg" alt="" />
                            F-3, 1st Floor, DLF Center Point Bata More, Metro
                            Pillor No.845, Sector-11, Mathura Road,
                            Faridabad-121006 (Haryana)
                          </NavLink>
                        </li>
                        <li>
                          <NavLink>
                            <img src="../images/Footer/call.svg" alt="" />
                            0129-4113080 <br></br>9953910070
                          </NavLink>
                        </li>
                        <li>
                          <NavLink>
                            <img src="../images/Footer/email.svg" alt="" />
                            info@insurancevaluator.com<br></br>
                            {/* pps.rec2010@gmail.com */}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          <NavLink className="copy-right">
            © 2024 Copyright insurancevaluator.com | All rights reserved ||
            Developed by Vikas Rawat
          </NavLink>
        </footer>
      </section>
    </>
  );
};

export default Footer;
