import axios from "axios";
const { APIURL } = require("global");
// eslint-disable-next-line no-undef
const getActiveTimelineList = async () => {
  const listApi = APIURL + "timeline/activeList";
  return await fetch(listApi);
};

const GetTimelineList = async (list) => {
  try {
    const URL = APIURL + `timeline/list`;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};



const GetTimelineListByID = async (id) => {

  try {
    const URL = APIURL + `timeline/` + id;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const CreateTimeline = async (data) => {
  try {
    const URL = APIURL + "timeline/create";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};
const UpdateTimeline = async (data) => {
  try {
    const URL = APIURL + "timeline/update";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};
const ActivateTimeline = async (id)=> {
 
  try {
    const URL = APIURL + "timeline/activate";
    return await axios.post(`${URL}`,  {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};
const DeactivateTimeline = async (id) => {

  try {
    const URL = APIURL + "timeline/deactivate";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};
const DeleteTimeline = async (id) => {

  try {
    const URL = APIURL + "timeline/delete";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};

const ActiveTimeline = async (id)=> {
  try {
    const URL = APIURL + "timeline/activeList";
    return await axios.get(`${URL}`);
  } catch (error) {
    return error.response;
  }
};

export {
  getActiveTimelineList,
  GetTimelineListByID,
  GetTimelineList,
  CreateTimeline,
  UpdateTimeline,
  ActivateTimeline,
  DeactivateTimeline,
  DeleteTimeline,
  ActiveTimeline,
};