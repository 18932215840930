import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleKeyPressforSpecialCharacters } from "components/RegexValidation";
import {
  createBlogCategory,
  updateBlogCategory,
  getBlogCategoryId,
  deleteBlog,
} from "services/blog.service";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const BlogCategoryAdd = () => {
  const param = useParams();

  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [blogCategoryID, setBlogCategoryID] = useState("");
  const [createby, setCreateby] = useState(user.AdminID);

  useEffect(() => {
    if (param !== null) {
      getBlogCategoryId(param.id).then((response) => {
        let blogCategoryDetail = response.data.Result[0];
        setBlogCategoryID(blogCategoryDetail.BlogCategoryID);
        setTitle(blogCategoryDetail.Title);
        setShortDescription(blogCategoryDetail.ShortDescription);
      });
    }
  }, []);

  const addBlog = async (e) => {
    e.preventDefault();

    if (title.trim() === '' || shortDescription.trim() === '') {
      // Handle the case when the input field is blank
      toast.warn("Input field cannot be blank");
      return;
    }

    const blogCategory = {
      id: e.target.id.value,
      title: title.replace("'", "''"),
      shortDescription: shortDescription.replace("'", "''"),
      createby: createby,
    };

    let response;

    if (e.target.id.value === "") {
      response = await createBlogCategory(blogCategory);
    } else {
      response = await updateBlogCategory(blogCategory);
    }

    if (response.status === 200 && !response.data.error) {
      toast.success(response.data.Message);

      setTimeout(() => {
        navigate("/admin/blogcategory");
      }, 2000);
    } else {
      toast.error(response.data.Message);
    }
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                {param.id ? "Edit" : "Add New"} Blog Category
              </h3>
            </div>

            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={addBlog}>
                    <input type="hidden" name="id" value={blogCategoryID} />
                    <input type="hidden" name="createby" value={createby} />
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={title}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Title"
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}
                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.preventDefault();
                          } else {
                            setTitle(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Description
                      </label>
                      <input
                        type="text"
                        name="shortDescription"
                        value={shortDescription}
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                        }`}
                        placeholder="Description"
                        // onChange={(e) => setShortDescription(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.preventDefault();
                          } else {
                            setShortDescription(e.target.value);
                          }
                        }}
                      />
                      <div className="text-left mt-6">
                        <button
                          className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default BlogCategoryAdd;
