import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./Health-Insurance.css";
import { Link } from "react-router-dom";
import { dynamicInvestmentMarathon } from "services/configuration.service";
import Timeline from "../Timeline/Timeline";
import Timeline2 from "../Timeline/Timeline2";
import Slider from "react-slick";
import Banner from "../Banner/Banner";

function HealthInsurance() {
  const [investmentMarathonData, setInvestmentMarathonData] = useState([]);
  useEffect(() => {
    dynamicInvestmentMarathon().then((response) => {
      setInvestmentMarathonData(response.data.Result[0]);
    });
  }, []);
  let setting = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let setting2 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Health Policy</h1>
      <p className="mb-4">
        Healthcare policy is crucial because it aids in establishing rules that
        are advantageous to patients, healthcare providers, and the healthcare
        system as a whole.{" "}
      </p>{" "}
      <p className="mb-4">
        Established rules and regulations can help avoid communication problems
        and human error in medical decisions.{" "}
      </p>{" "}
      <p className="mb-4">
        Awareness and adherence to policies may assist health administrators and
        medical professionals in providing the best possible care to patients
        while leveraging their expertise to help shape future policies.
      </p>{" "}
      <p className="mb-4">
        Knowledge of health policy benefits the medical staff and patients, who
        can also be aware of their basic rights and benefits as a patient.
      </p>
    </div>
  );
}

export default HealthInsurance;
