// import React from "react";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "./Partnership.css";
import { getparnerList } from "services/partner.service";
import { ConnectWithUs } from "services/user.service";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleKeyPressforSpecialCharacters } from "components/RegexValidation";
import { handleKeyPressForNumbers } from "components/RegexValidation";

const { IMAGEURL } = require("global");

function Partnership() {
  const [partners, setPartners] = useState([]);
  const [connectWithUs, setConnectWithUs] = useState();

  useEffect(() => {
    getparnerList()
      .then((response) => response.json())
      .then((data) => {
        setPartners(data.Result);
      });
  }, []);

  let setting = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleChange = (e) => {
    setConnectWithUs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    ConnectWithUs(connectWithUs).then((response) => {
      if (response.data.errorCode === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    });
  };

  return (
    <>
      <section className="pg-partnership">
        {/* Banner */}
        <section className="ev-Banner_sec">
          <div className="d-flex flex-column justify-content-center">
            <div className="grid-50 coverup">
              <h1>Together We Grow</h1>
              <p>Coming together to enhance future education</p>
              <Link to="/contactUs">
                <button className="line-btn">
                  Let's Engage <img alt="imgNotFound" src="" />
                </button>
              </Link>
            </div>
          </div>
        </section>

        {/* About */}
        <section className="ev-about-sec">
          <div className="flexi">
            <div className="container">
              <Row>
                <Col lg={6}>
                  <div className="grid-50 about-left_sec">
                    <div className="inner_wrapper">
                      <h3 className="text-[30px]">
                        Leverage our network to improve your user base
                      </h3>
                      <p>
                        Amar Financial offers a partnership with Auro Scholar,
                        the world’s 1st micro scholarship programme aimed at
                        improving the learning outcomes of the school students
                        by authenticating their learning levels, hence, creating
                        mass incentives to learn.
                      </p>
                      <p>
                        Auro Scholar awards monthly micro scholarships to
                        students on achieving an 80% performance score in a
                        10-min curriculum-aligned quiz.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="grid-50 about-right_sec">
                    <div className="about_img">
                      <img src="images/Home/Banner.svg" alt="imgNotFound" />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* Benefits */}
        <section className="ev-philosophy_sec">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h3>Benefits for EdTech startups</h3>
                <div className="bottom_features">
                  <ul>
                    <li>
                      <NavLink>
                        <img
                          src="images/Partnership/LaunchScholarship.svg"
                          alt="imgNotFound"
                        />
                        <p>
                          Launch ‘your’ scholarship programme, powered by Auro
                          Scholar
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img
                          src="images/Partnership/IncreaseReach.svg"
                          alt="imgNotFound"
                        />
                        <p>Increase reach & engagement</p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img
                          src="images/Partnership/ReduceYourCustomer.svg"
                          alt="imgNotFound"
                        />
                        <p>
                          Reduce your customer acquisition cost by converting to
                          paid user
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img
                          src="images/Partnership/Attract&retain.svg"
                          alt="imgNotFound"
                        />
                        <p>Attract & retain users</p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <img
                          src="images/Partnership/ImproveProduct.svg"
                          alt="imgNotFound"
                        />
                        <p>
                          Improve product targeting through learning
                          intelligence data
                        </p>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Partners */}
        <section className="our_partners">
          <div className="flexi">
            <div className="container">
              <div className="flexi">
                <h3 className="mb-4">Our Partners</h3>

                <Slider {...setting}>
                  {partners.length > 0
                    ? partners.map((partnerData, index) => (
                        <>
                          <div className="item">
                            <img
                              src={`${IMAGEURL}${partnerData.ProfilePic}`}
                              className="testi-img"
                              alt=""
                            />
                          </div>
                        </>
                      ))
                    : ""}
                  {/* <div className="item">
                    <img src="images/Home/Banner.sv
                    .............000000000000000g" className="new-img" />
                  </div>
                  <div className="item">
                    <img src="images/Home/Banner.svg" className="new-img" />
                  </div>
                  <div className="item">
                    <img src="images/Home/Banner.svg" className="new-img" />
                  </div>
                  <div className="item">
                    <img src="images/Home/Banner.svg" className="new-img" />
                  </div>
                  <div className="item">
                    <img src="images/Home/Banner.svg" className="new-img" />
                  </div>
                  <div className="item">
                    <img src="images/Home/Banner.svg" className="new-img" />
                  </div>
                  <div className="item">
                    <img src="images/Home/Banner.svg" className="new-img" />
                  </div> */}
                </Slider>
                {/* <div className="slide_box">
                  <div className="owl-wrapper">
                    <div
                      className="owl-carousel owl-theme owl-2"
                      id="new_slide"
                    ></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* Partners Form */}
        <section className="partner_form-sec">
          <div className="flexi">
            <div className="partner-us">
              <Container>
                <h2>Partner With Us</h2>
                <Row className="partner-us-content">
                  <Col lg={5}>
                    <div className="img_sec">
                      <img src="images/Partnership/partner_img.svg" alt="" />
                    </div>
                  </Col>
                  <Col lg={7}>
                    <form className="form_sec" onSubmit={handleSubmit}>
                      <Row className="">
                        <Col md={6}>
                          <input
                            type="text"
                            className="part-form-content pt-lf"
                            placeholder="Name"
                            required
                            name="name"
                            onKeyPress={handleKeyPressforSpecialCharacters}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            className="part-form-content pt-rt"
                            placeholder="Mobile"
                            required
                            name="mobile"
                            minLength={10}
                            maxLength={10}
                            onKeyPress={handleKeyPressForNumbers}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <input
                            type="email"
                            className="part-form-content pt-lf"
                            placeholder="Email"
                            name="email"
                            required
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={6}>
                          <input
                            type="text"
                            className="part-form-content pt-rt"
                            placeholder="Company Name "
                            name="company"
                            required
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row className="">
                        <Col md={6}>
                          {/* <input
                            type="password"
                            className="part-form-content "
                            placeholder="Password"
                          /> */}
                          <div className="button part-form-content pt-lf">
                            <button className="green_btn">
                              Submit
                              <img src="images/Home/white_arrow.svg" alt="" />
                            </button>
                          </div>
                        </Col>
                        <Col md={6}>
                          {/* <div className="button part-form-content pt-rt">
                            <button className="green_btn">
                              Submit
                              <img src="images/Home/white_arrow.svg" alt="imgNotFound" />
                            </button>
                          </div> */}
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="container">
              {/* <Row>
                <Col lg={6}>
                  <form className="form_sec">
                    <h2>Partner With Us</h2>
                    <input type="text" className="" placeholder="Name" />
                    <input type="number" className="" placeholder="Mobile" />
                    <input type="email" className="" placeholder="Email" />
                    <input type="text" className="" placeholder="Company Name" />
                    <input type="password" className="" placeholder="Password" />
                    <div className="button">
                      <button className="green_btn">
                        Submit
                        <img src="images/Home/white_arrow.svg" />
                      </button>
                    </div>
                  </form>
                </Col>
                <Col lg={6}>
                  <div className="img_sec">
                    <img src="images/Partnership/partner_img.svg" />
                  </div>
                </Col>
              </Row> */}
            </div>
          </div>
        </section>
      </section>
      <ToastContainer />
    </>
  );
}

export default Partnership;
