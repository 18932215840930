import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState } from "react";

import { Col, Row } from "react-bootstrap";
import { createApplication } from "services/application.service";
import BusinessPlan from "./BusinessPlan";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

function DetailedInfo({ formData }, props) {
  const [rows, setRows] = useState([{}]);
  const user = JSON.parse(localStorage.getItem("loggedUser"));
  
  const handleAddRow = () => {
    formData.setRows([...formData.rows, { createby: user.userId }]);
  };

  const handleInputChange = (e, index, field) => {
    const newRows = [...formData.rows];
    newRows[index][field] = e.target.value;
    formData.setRows(newRows);
  };

  const handleDeleteRow = (index) => {
    const newRows = [...formData.rows];
    newRows.splice(index, 1);
    formData.setRows(newRows);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div id="tab1" className="tab_content">
        <h4>
          <img src="images/Dashboard/dollar.svg" />
          About Your Venture
        </h4>
        <Row className="form_sec">
          <Col md={4}>
            <div className="form_group">
              <label>Brand Name</label>
              <input
                type="text"
                name="brandName"
                value={formData.dashboardData.brandName}
                onChange={(e) => {
                  formData.setDashboardData({
                    ...formData.dashboardData,
                    brandName: e.target.value,
                  });
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="form_group">
              <label>Website</label>
              <input
                type="text"
                name="website"
                value={formData.dashboardData.website}
                onChange={(e) => {
                  formData.setDashboardData({
                    ...formData.dashboardData,
                    website: e.target.value,
                  });
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="form_group">
              <label>City Name</label>
              <input type="text" />
            </div>
          </Col>
          <Col md={4}>
            <div className="form_group">
              <label>
                Venture Stage <span>*</span>
              </label>
              <select onChange={(e)=>{formData.setDashboardData({...formData.dashboardData, ventureStage: e.target.value})}} name="ventureStage">
                <option value="">Please Select</option>
                <option value="Idea-Stage">Idea-Stage</option>
                <option value="Prototype in Place">Prototype in Place</option>
                <option value="Pilot Started">Pilot Started</option>
                <option value="Product Launched">Product Launched</option>
                <option value="Free Customer Usage">Free Customer Usage</option>
                <option value="Monetisation Started">
                  Monetisation Started
                </option>
              </select>
            </div>
          </Col>
          <Col md={4}>
            <div className="form_group">
              <label>
                Funding Stage <span>*</span>
              </label>
              <select
                onChange={(e) => {
                  formData.setDashboardData({
                    ...formData.dashboardData,
                    fundingStage: e.target.value,
                  });
                }}
                name="fundingStage"
              >
                <option value="">Please Select</option>
                <option value="Bootstrapped">Bootstrapped</option>
                <option value="Seed Stage">Seed Stage</option>
                <option value="Pre-Series A">Pre-Series A</option>
                <option value="Series A">Series A</option>
                <option value="Series B">Series B</option>
                <option value="Series C">Series C</option>
                <option value="Series D">Series D</option>
              </select>
            </div>
          </Col>
          <Col md={4}>
            <div className="form_group">
              <label>
                Purpose of Application <span>*</span>
              </label>
              <select
                onChange={(e) => {
                  formData.setDashboardData({
                    ...formData.dashboardData,
                    purpose: e.target.value,
                  });
                }}
                name="purpose"
              >
                <option value="">Please Select</option>
                <option value="Acceleration Program">
                  Acceleration Program
                </option>
                <option value="Equity Investment">Equity Investment</option>
                <option value="Partnership">Partnership</option>
                <option value="All of the Above">All of the Above</option>
              </select>
            </div>
          </Col>
          <Col md={4}>
            <div className="form_group">
              <label>Incorporation Date</label>
              <input
                type="date"
                id="datepicker"
                name="incorporationDate"
                onChange={(e) => {
                  formData.setDashboardData({
                    ...formData.dashboardData,
                    incorporationDate: e.target.value,
                  });
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="form_group">
              <label>Go to Market Date</label>
              <input
                type="date"
                id="datepicker_2"
                name="marketDate"
                onChange={(e) => {
                  formData.setDashboardData({
                    ...formData.dashboardData,
                    marketDate: e.target.value,
                  });
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="form_group">
              <label>
                Category <span>*</span>
              </label>
              <select
                name="category"
                onChange={(e) => {
                  formData.setDashboardData({
                    ...formData.dashboardData,
                    category: e.target.value,
                  });
                }}
              >
                <option value="">Please Select</option>
                <option value="Related to education">
                  Related to education
                </option>
                <option value="Not related to education">
                  Not related to education
                </option>
              </select>
            </div>
          </Col>
          <Col md={4}>
            <div className="form_group">
              <label>
                Stage <span>*</span>
              </label>
              <select
                onChange={(e) => {
                  formData.setDashboardData({
                    ...formData.dashboardData,
                    stage: e.target.value,
                  });
                }}
                name="stage"
              >
                <option value="">Please Select</option>
                <option value="Idea Stage - not yet launched in the market">
                  Idea Stage - not yet launched in the market
                </option>
                <option value="Startup launched in the market">
                  Startup launched in the market
                </option>
              </select>
            </div>
          </Col>
        </Row>
        <div className="flexi form_sec">
          <div className="grid-100">
            <div className="table_sec">
              <h4>
                <img src="images/Dashboard/dollar.svg" />
                Core Team Details
                <button className="add-coreteams" onClick={handleAddRow}>
                  <FontAwesomeIcon icon={faPlus} style={{ color: "#41ac3e" }} />
                </button>
              </h4>
              {/*  
                                <Table responsive>
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      {Array.from({ length: 12 }).map(
                                        (_, index) => (
                                          <th key={index}>Table heading</th>
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      {Array.from({ length: 12 }).map(
                                        (_, index) => (
                                          <td key={index}>
                                            Table cell {index}
                                          </td>
                                        )
                                      )}
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      {Array.from({ length: 12 }).map(
                                        (_, index) => (
                                          <td key={index}>
                                            Table cell {index}
                                          </td>
                                        )
                                      )}
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      {Array.from({ length: 12 }).map(
                                        (_, index) => (
                                          <td key={index}>
                                            Table cell {index}
                                          </td>
                                        )
                                      )}
                                    </tr>
                                  </tbody>
                                </Table>
                                  */}
              <table
                data-dynamicrows
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th className="lg:!w-[40%]">S.no.</th>
                    <th>Full Name</th>
                    <th>Age</th>
                    <th>Latest Qualification</th>
                    <th>Latest Institute Attended</th>
                    <th>Total Work Experience</th>
                    <th>Role</th>
                    <th>Member Status</th>
                    <th className="lg:!w-[40%]">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.rows.map((row, index) => (
                    <tr key={index}>
                      <td className="controls lg:!w-[40%]">
                        <input
                          type="text"
                          className="form-control"
                          // value={rows.sno || ""}
                          onChange={(e) => handleInputChange(e, index, "sno")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          // value={rows.name || ""}
                          onChange={(e) =>
                            handleInputChange(e, index, "fullName")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          // value={rows.age || ""}
                          onChange={(e) => handleInputChange(e, index, "age")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          // value={rows.qual || ""}
                          onChange={(e) =>
                            handleInputChange(e, index, "latestQualification")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          // value={rows.inst || ""}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              index,
                              "latestInstituteAttended"
                            )
                          }
                        />
                      </td>
                      <td>
                        <select
                          // value={rows.exp || ""}
                          onChange={(e) =>
                            handleInputChange(e, index, "totalWorkExperience")
                          }
                        >
                          <option value="">Please Select</option>
                          <option value="Student">Student</option>
                          <option value="Less than 5 years">
                            Less than 5 years
                          </option>
                          <option value="5-10 years">5-10 years</option>
                          <option value="10-20 years">10-20 years</option>
                          <option value="More than 20 years">
                            More than 20 years
                          </option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          // value={rows.role || ""}
                          onChange={(e) => handleInputChange(e, index, "role")}
                        />
                      </td>
                      <td>
                        <select
                          // value={rows.mstatus || ""}
                          onChange={(e) =>
                            handleInputChange(e, index, "memberStatus")
                          }
                        >
                          <option value="">Please Select</option>
                          <option value="Founder (Holds Shares)">
                            Founder (Holds Shares)
                          </option>
                          <option value="Core Team Member (Does not Hold Shares)">
                            Core Team Member (Does not Hold Shares)
                          </option>
                        </select>
                      </td>
                      <td className="lg:!w-[40%]">
                        <button onClick={() => handleDeleteRow(index)}>
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ color: "#d81313" }}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <button
          className="head-cto"
          onClick={(e) => formData.handleTabs(e, "detail")}
        >
          Next
        </button>
      </div>
    </>
  );
}

export default DetailedInfo;
