import axios from "axios";
const { APIURL } = require("global");

const getConfigurationList = async () => {
  try {
    const URL = APIURL + "configurations/list";
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const updateConfiguration = async (data) => {
  try {
    const URL = APIURL + "configurations/update";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const deleteConfiguration = async (id) => {
  try {
    const URL = APIURL + "configurations/delete";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};

const activateConfiguration = async (id) => {
  try {
    const URL = APIURL + "configurations/activate";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};

const deactivateConfiguration = async (id) => {
  try {
    const URL = APIURL + "configurations/deactivate";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};

const getListByConfigurationId = async (id) => {
  try {
    const URL = APIURL + `configurations/` + id;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export {
  getConfigurationList,
  updateConfiguration,
  activateConfiguration,
  deactivateConfiguration,
  deleteConfiguration,
  getListByConfigurationId
};
