import axios from "axios";
const { APIURL } = require("global");

const getBlogList = async () => {
  try {
    const URL = APIURL + "blog/list";
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const getBlogContentByID = async (id) => {
  try {
    const URL = APIURL + `blog/${id}`;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const getblogcategory = async (list) => {
  try {
    const URL = APIURL + `blogCategory/list`;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const getActiveBlogCategory = async (list) => {
  try {
    const URL = APIURL + `blogCategory/activeList`;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const getBlogCategoryId = async (id) => {
  try {
    const URL = APIURL + `blogCategory/` + id;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const createBlogCategory = async (data) => {
  try {
    const URL = APIURL + "blogCategory/create";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const updateBlogCategory = async (data) => {
  try {
    const URL = APIURL + "blogCategory/update";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const deleteBlogCategory = async (id) => {
  try {
    const URL = APIURL + "blogCategory/delete";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};

const activateBlogCategory = async (id) => {
  try {
    const URL = APIURL + "blogCategory/activate";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};

const deactivateBlogCategory = async (id) => {
  try {
    const URL = APIURL + "blogCategory/deactivate";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};

const getBlogs = async () => {
  try {
    const URL = APIURL + `blog/list`;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const getBlogId = async (id) => {
  try {
    const URL = APIURL + `blog/` + id;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const createBlog = async (data) => {
  try {
    const URL = APIURL + "blog/create";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const updateBlog = async (data) => {
  try {
    const URL = APIURL + "blog/update";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const deleteBlog = async (id) => {
  try {
    const URL = APIURL + "blog/delete";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};

const activateBlog = async (id) => {
  try {
    const URL = APIURL + "blog/activate";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};

const deactivateBlog = async (id) => {
  try {
    const URL = APIURL + "blog/deactivate";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};

const blogDataByBlogCategoryID = async (id) => {
  try {
    const URL = APIURL + "blog/blogCategoryID/" + id;
    return await axios.get(`${URL}`);
  } catch (error) {
    return error.response;
  }
};

const blogDataByTitle = async (data) => {
  try{
    const URL = APIURL + "blog/blogDataByTitle";
    return await axios.post(`${URL}`, data);
  }
  catch (error){
    return error.response;
  }
}

const recentPost = async () => {
  try{
    const URL = APIURL + "blog/recentBlog";
    return await axios.get(`${URL}`);
  }
  catch (error){
    return error.response;
  }
}

const getActiveBlogList = async () => {
  try {
    const URL = APIURL + "blog/activeList";
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export {
  getBlogList,
  getBlogContentByID,
  getBlogCategoryId,
  getblogcategory,
  createBlogCategory,
  updateBlogCategory,
  deleteBlogCategory,
  activateBlogCategory,
  deactivateBlogCategory,
  getBlogId,
  getBlogs,
  createBlog,
  updateBlog,
  deleteBlog,
  activateBlog,
  deactivateBlog,
  getActiveBlogCategory,
  blogDataByBlogCategoryID,
  blogDataByTitle,
  recentPost,
  getActiveBlogList
};
