/**
 *this api use for to show careerList
 */
const { APIURL } = require("global");
// eslint-disable-next-line no-undef
const getcareerList = async () => {
  const listApi = APIURL + "careers/list";
  return await fetch(listApi);
};

export { getcareerList };
