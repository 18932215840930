import React, { useEffect, useState } from "react";
import { getSubscriptionList } from "services/subscription.service";
import { createApplication } from "services/application.service";

function EvaluationReport({formData}) {
  const {dashboardData, rows} = formData;
  const [subscriptionList, setSubscriptionList] = useState([]);

  useEffect(() => {
    getSubscriptionList()
      .then((response) => response.json())
      .then((data) => {
        setSubscriptionList(data.Result);
      });
  }, []);

  const handleSubmit = (e, SubscriptionID) =>{
    // e.preventDefault();
    let applicationData = {}
    // applicationData = formData.dashboardData;
    applicationData.subscriptionPlanID = SubscriptionID;
    applicationData.formStatus = "subscriptionSubmit";
    applicationData.applicationMember = rows;
    createApplication(applicationData).then((response) => {
      // if (response.data.Status === "Success") {
        
      // }
      console.log(response)
    });
  }
  return (
    <>
      <div className="plans_view tab_content">
        <h2>
          We thrive to make a business successful! Our only question is, will it
          be yours?
        </h2>
        <ul>
          {subscriptionList?.map((data, index) => (
            <>
              <li key={index}>
                <div className="plan-side">
                  <h5>{data.Title}</h5>
                  <h3>&#8377;{data.Price}</h3>
                  <ul>
                    {data.Point1 !== null ? (
                      <li>
                        <img src="images/Dashboard/popup_tick.svg" />
                        {data.Point1}
                      </li>
                    ) : (
                      ""
                    )}
                    {data.Point2 !== null ? (
                      <li>
                        <img src="images/Dashboard/popup_tick.svg" />
                        {data.Point2}
                      </li>
                    ) : (
                      ""
                    )}
                    {data.Point3 !== null ? (
                      <li>
                        <img src="images/Dashboard/popup_tick.svg" />
                        {data.Point3}
                      </li>
                    ) : (
                      ""
                    )}
                    {data.Point4 !== null ? (
                      <li>
                        <img src="images/Dashboard/popup_tick.svg" />
                        {data.Point4}
                      </li>
                    ) : (
                      ""
                    )}
                    {data.Point5 !== null ? (
                      <li>
                        <img src="images/Dashboard/popup_tick.svg" />
                        {data.Point5}
                      </li>
                    ) : (
                      ""
                    )}
                    {data.Point6 != null ? (
                      <li>
                        <img src="images/Dashboard/popup_tick.svg" />
                        {data.Point6}
                      </li>
                    ) : (
                      ""
                    )}
                    {data.Point7 != null ? (
                      <li>
                        <img src="images/Dashboard/popup_tick.svg" />
                        {data.Point7}
                      </li>
                    ) : (
                      ""
                    )}
                    {data.Point8 != null ? (
                      <li>
                        <img src="images/Dashboard/popup_tick.svg" />
                        {data.Point8}
                      </li>
                    ) : (
                      ""
                    )}
                    <li>&nbsp;</li>
                  </ul>
                  <button className="green_btn head-cto" onClick={(e)=>{handleSubmit(e, data.SubscriptionID)}}>Proceed</button>
                </div>
              </li>
            </>
          ))}
        </ul>
      </div>
    </>
  );
}

export default EvaluationReport;
