import { Navigate } from "react-router-dom";
import Dashboard from "../components/admin/Dashboard/Dashboard";
import AddUsermanagement from "../components/admin/usermanagement/AddUsermanagement";
import ListUserManagement from "../components/admin/usermanagement/ListUserManagement";
import BlogCategory from "components/admin/Blog/BlogCategory";
import { BlogCategoryAdd } from "components/admin/Blog/BlogCategoryAdd";
import Listportfolio from "components/admin/PortFolio/Listportfolio";
import Addportfolio from "components/admin/PortFolio/Addportfolio";

import ListConfiguration from "components/admin/Configuration/ListConfiguration";
import AddConfiguration from "components/admin/Configuration/AddConfiguration";
import Blog from "components/admin/Blog/Blog";
import BlogAdd from "components/admin/Blog/BlogAdd";
import Application from "components/admin/Applications/applications";
import ApplicationRead from "components/admin/Applications/applicationRead";
import TestimonialList from "components/admin/Testimonial/TestimonialList";
import AddTestimonial from "components/admin/Testimonial/AddTestimonial";
import Partners from "components/admin/Partners/Partners";
import PartnerAdd from "components/admin/Partners/PartnerAdd";
import ListTimeLine from "components/admin/Timeline/ListTimeline";
import TimelineAdd from "components/admin/Timeline/AddTimeline";
import AddSubscription from "components/admin/Subscription/AddSubscription";
import ListSubscription from "components/admin/Subscription/ListSubscription";
import NewsLetter from "components/admin/Newsletter/newsletter";
import Enquiry from "components/admin/Enquiry Master/Enquiry";
import AddFeedback from "components/admin/Enquiry Master/FeedbackAdd";
import AddImage from "components/admin/Image Master/ImageAdd";
import Image from "components/admin/Image Master/Image";
import EditImage from "components/admin/Image Master/ImageEdit";

export const adminRoutes = [
  {
    path: "",
    component: <Navigate to="/admin/dashboard" replace />,
  },
  {
    path: "dashboard",
    component: <Dashboard />,
  },
  {
    path: "userlist",
    component: <ListUserManagement />,
  },

  {
    path: "adduser",
    component: <AddUsermanagement />,
  },
  {
    path: "editUser/:id",
    component: <AddUsermanagement />,
  },
  {
    path: "portfoliolist",
    component: <Listportfolio />,
  },
  {
    path: "addportfolio",
    component: <Addportfolio />,
  },

  {
    path: "editportfolio/:id",
    component: <Addportfolio />,
  },

  // {
  //   path: "careerlist",
  //   component: <CareerList />,
  // },

  // {
  //   path: "addcareer",
  //   component: <CareerAdd />,
  // },

  // {
  //   path: "editcreer/:id",
  //   component: <CareerAdd />,
  // },

  {
    path: "configurationlist",
    component: <ListConfiguration />,
  },

  {
    path: "editconfiguration/:id",
    component: <AddConfiguration />,
  },

  {
    path: "blogcategory",
    component: <BlogCategory />,
  },

  {
    path: "blogcategory",
    component: <BlogCategory />,
  },

  {
    path: "addblogcategory",
    component: <BlogCategoryAdd />,
  },
  {
    path: "editblogcategory/:id",
    component: <BlogCategoryAdd />,
  },
  {
    path: "blog",
    component: <Blog />,
  },
  {
    path: "addblog",
    component: <BlogAdd />,
  },
  {
    path: "editblog/:id",
    component: <BlogAdd />,
  },
  {
    path: "application",
    component: <Application />,
  },
  {
    path: "addApplication/:id",
    component: <ApplicationRead />,
  },
  {
    path: "testimoniallist",
    component: <TestimonialList />,
  },
  {
    path: "addtestimonial",
    component: <AddTestimonial />,
  },
  {
    path: "edittestimonial/:id",
    component: <AddTestimonial />,
  },
  {
    path: "enquirylist",
    component: <Enquiry />,
  },
  {
    path: "addFeedback/:id",
    component: <AddFeedback />,
  },
  {
    path: "imagelist",
    component: <Image />,
  },
  {
    path: "addimage/:id",
    component: <AddImage />,
  },
  {
    path: "addimage",
    component: <AddImage />,
  },
  {
    path: "editimage/:id",
    component: <EditImage />,
  },
  {
    path: "partneradd",
    component: <PartnerAdd />,
  },
  {
    path: "editpartner/:id",
    component: <PartnerAdd />,
  },
  {
    path: "timeline",
    component: <ListTimeLine />,
  },
  {
    path: "addtimeline",
    component: <TimelineAdd />,
  },
  {
    path: "edittimeline/:id",
    component: <TimelineAdd />,
  },
  { path: "subscription", component: <ListSubscription /> },
  { path: "addsubscription", component: <AddSubscription /> },
  { path: "editsubscription/:id", component: <AddSubscription /> },
  {
    path: "newsletter",
    component: <NewsLetter />,
  },
];
