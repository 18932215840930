import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userNameRegex } from "components/RegexValidation";
import { NameRegex } from "components/RegexValidation";
import { handleKeyPressForNumbers } from "components/RegexValidation";
import { handleKeyPressforSpecialCharacters } from "components/RegexValidation";
import {
  // getActiveUserList,
  userAdd,
  userUpdate,
  getUsersId,
  deleteUser,
} from "../../services/user.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const UserAddView = ({ param }) => {
  const [error, setError] = useState(false);
  // const [userNameError, setUserNameError] = useState(false);
  const [nameError, setNameError] = useState(false);
  // const [emailError, setEmailError] = useState(false);
  // const [passwordError, setPasswordError] = useState(false);
  // const [contactError, setContactError] = useState(false);

  const [userNameError, setUserNameError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  // const [pswdtype, Setpswdtype] = useState("password");

  const [contact, setContact] = useState("");
  const [pswdtype, Setpswdtype] = useState("password");

  const [userID, setUserID] = useState();
  const [createby, setCreateby] = useState(user.AdminID);

  const [inputValue, setInputValue] = useState("");

  let pswdToggle = () => {
    if (pswdtype == "password") {
      Setpswdtype("text");
    } else {
      Setpswdtype("password");
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData("text/plain");
    const pattern = /^[a-zA-Z0-9]*$/; // Regular expression pattern to allow alphanumeric characters

    if (pattern.test(pastedValue)) {
      setInputValue(pastedValue);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.trim() === "") {
      // Blank spaces detected, do not update the password state
      return;
    }
    setPassword(value);
  };

  useEffect(() => {
    if (param !== null) {
      console.log(param);
      if (param && param.id) {
        getUsersId(param.id).then((response) => {
          let userListDetail = response.data.Result[0];
          console.log(userListDetail);
          setUserID(userListDetail.UserID);
          setUsername(userListDetail.Username);
          setName(userListDetail.Name);
          setEmail(userListDetail.Email);
          setPassword(userListDetail.Password);
          setContact(userListDetail.Contact);
        });
      }
    }
  }, []);

  const addUserdata = async (e) => {
    e.preventDefault();
    const isUserNameValid = userName.match(userNameRegex);

    if (isUserNameValid === null) {
      setError(true);
      setUserNameError(false);
      toast.warn("Username is invalid");
      return;
    } else {
      const userDataList = {
        userName: userName,
        email: email,
        name: name.trim(),
        password: password,
        contact: contact,
        userID: e.target.id.value,
        createby: createby,
      };

      if (e.target.id.value === "") {
        userAdd(userDataList)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);

            if (data.Status === "Success") {
              toast.success(data.Message);
              setTimeout(() => {
                navigate("/admin/userlist");
              }, 2000);
            } else {
              toast.error(data.Message);
            }
          });
      } else {
        userUpdate(userDataList)
          .then((response) => response.json())
          .then((data) => {
            // const isBoolean = true;
            console.log("aisucia", data);

            if (data.Status === "Success") {
              toast.success(data.Message);
              setTimeout(() => {
                navigate("/admin/userlist");
              }, 2000);
            } else {
              toast.error(data.message);
            }
          });
      }
    }
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                {param.id ? "Edit" : "Add New"} User
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={addUserdata}>
                    <input type="hidden" name="id" value={userID} />
                    <input type="hidden" name="createby" value={createby} />
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        User Name
                      </label>
                      <input
                        type="text"
                        name="userName"
                        required
                        value={userName}
                        // onKeyDown={(e)=>{
                        //   if(e.key === ' '){
                        //     e.preventDefault()
                        //   }
                        // }}

                        maxLength={50}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="UserName"
                        //   onChange={(e) => setUsername(e.target.value)}
                        // />

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            setUserNameError(false);
                            e.preventDefault();
                          } else if (
                            !(e.target.value === "") &&
                            !userNameRegex.test(e.target.value)
                          ) {
                            setUserNameError(true);

                            setUsername(e.target.value);
                          } else {
                            setUserNameError(false);
                            setUsername(e.target.value);
                          }
                        }}
                      />
                      {userNameError && (
                        <span style={{ color: "red" }}>Invalid UserName</span>
                      )}
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        required
                        value={name}
                        maxLength={20}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Name"
                        onPaste={handlePaste}
                        // onChange={(e) => {
                        //   setName(e.target.value);
                        // }}
                        onKeyPress={handleKeyPressforSpecialCharacters}
                        onChange={(e) => {
                          if (e.target.value === " ") {
                            //  setNameError(false);
                            e.preventDefault();
                          } else {
                            //  setNameError(false);
                            setName(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        required
                        value={email}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        // onChange={emailValidate}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>

                    {param.id ? (
                      ""
                    ) : (
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Passwords
                        </label>
                        <div className="relative">
                          <input
                            type={pswdtype}
                            name="password"
                            required
                            value={password}
                            maxLength={16}
                            className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                            placeholder="Password"
                            onKeyPress={(e) => {
                              if (e.which === 32) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              if (e.target.value === " ") {
                                // setUserNameError(false);
                                e.preventDefault();
                              } else {
                                setPassword(e.target.value);
                              }
                            }}
                          />
                          <div
                            className="absolute right-3 top-5 cursor-pointer"
                            onClick={pswdToggle}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              className={`${
                                pswdtype === "password" ? "block" : "hidden"
                              }`}
                            />
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              className={`${
                                pswdtype === "text" ? "block" : "hidden"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Contact
                      </label>
                      <input
                        type="text"
                        name="contact"
                        required
                        value={contact}
                        minLength={10}
                        maxLength={10}
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                        }`}
                        placeholder="Contact"
                        onKeyPress={handleKeyPressForNumbers}
                        // onChange={(e) => {

                        //   setContact(e.target.value);
                        // }}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.preventDefault();
                          } else {
                            setContact(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                    {/* </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};
