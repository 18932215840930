import axios from "axios";
const { APIURL } = require("global");

const getApplicationList = async () => {
  try {
    const URL = APIURL + "applications/list";
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const createApplication = async (data) => {
  try {
    const URL = APIURL + "applications/submit";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const applicationRead = async(id) => {
  try {
    const URL = APIURL + "applications/read/" + id;
    return await axios.get(`${URL}`);
  } catch (error) {
    return error.response;
  }
}

const applicationReview = async(data) => {
  try {
    const URL = APIURL + "applications/changeToReviewStatus";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
}

const applicationAccept = async(data) => {
  try {
    const URL = APIURL + "applications/changeToAcceptStatus";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
}

const applicationReject = async(data) => {
  try {
    const URL = APIURL + "applications/changeStatusToReject";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
}

const getApplicationListForDashboard = async (id) => {
  try {
    const URL = APIURL + "applications/dashboardList/"+ id;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export {
    createApplication,
    getApplicationList,
    applicationRead,
    applicationReview,
    applicationAccept,
    applicationReject,
    getApplicationListForDashboard,
}