import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getImageByComponentId } from "services/image.service";
import { getComponent } from "services/image.service";
import { activeImage } from "services/image.service";
import { inActiveImage } from "services/image.service";
import Pagination from "../Admin-Pagination/Pagination";

const Image = () => {
  const navigate = useNavigate();
  const [imageData, setImageData] = useState([]);
  const [component, setComponent] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [formData, setFormData] = useState({
    componentId: null,
  });

  useEffect(() => {
    getComponent()
      .then((response) => {
        setComponent(response.data.Result);
        if (response.data.Result.length > 0) {
          const firstComponentId = response.data.Result[0].id;
          let param = {
            id: firstComponentId,
          };
          fetchYourApi(param);
        }
      })
      .catch((error) => {
        console.error("Error fetching enquiry data:", error);
      });
  }, []);

  const handleChange = async (e) => {
    const selectedComponentId = e.target.value;
    setFormData({ ...formData, componentId: selectedComponentId });
    try {
      let param = {
        id: selectedComponentId,
      };
      await fetchYourApi(param);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchYourApi = async (selectedComponentId) => {
    getImageByComponentId(selectedComponentId)
      .then((response) => {
        setImageData(response.data.Result);
      })
      .catch((error) => {
        console.error("Error fetching enquiry data:", error);
      });
  };
  const handleNavigate = () => {
    navigate("/admin/addimage");
  };

  const activateImage = async (id) => {
    let obj = {
      id: id,
    };
    activeImage(obj).then((response) => {
      if (response.data.Status === "Success" && !response.data.Error) {
        const notify = () => {
          toast.success(response.data.Message);
        };
        const image = imageData.map((item) => {
          if (item.id === id) {
            return { ...item, Status: "Active" };
          } else {
            return { ...item };
          }
        });
        setImageData(image);
        notify();
      } else {
        const notify = () => {
          toast.error(Object.values(response.data.errors)[0][0]);
        };
        notify();
      }
    });
  };
  const inactiveImage = async (id) => {
    let obj = {
      id: id,
    };
    inActiveImage(obj).then((response) => {
      if (response.data.Status === "Success" && !response.data.Error) {
        const image = imageData.map((item) => {
          if (item.id === id) {
            return { ...item, Status: "Inactive" };
          } else {
            return { ...item };
          }
        });
        setImageData(image);
        const notify = () => {
          toast.success(response.data.Message);
        };
        notify();
      } else {
        const notify = () => {
          toast.error(Object.values(response.data.errors)[0][0]);
        };
        notify();
      }
    });
  };
  return (
    <>
      <div className="flex justify-between items-center mt-6">
        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Select Component
          </label>
          <select
            name="componentId"
            className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
            value={
              formData.componentId === null
                ? "Select Component"
                : formData.componentId
            }
            onChange={handleChange}
          >
            {component.map((component) => (
              <option key={component.id} value={component.id}>
                {component.Name}
              </option>
            ))}
          </select>
        </div>
        <div className="text-left mt-6">
          <button
            className="bg-green-600 active:bg-green-900 text-sm font-bold uppercase px-5 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={handleNavigate}
          >
            Add
          </button>
        </div>

        {/* <button
          className="bg-blue-600 active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={handleNavigate}
        >
          Add Image
        </button> */}
      </div>

      <div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
        <div className="mb-0 px-4 py-3 border-0 bg-slate-300">
          <div className="flex flex-wrap items-center">
            <div className="w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-black">Image Master</h3>
            </div>
          </div>
        </div>

        <div className=" overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr className="bg-blue-950">
                {" "}
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Sr.No
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Component Name
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Image
                </th>{" "}
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Status
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData?.map((datalist, index) => {
                return (
                  <tr key={index}>
                    {" "}
                    <th className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {index + 1}
                    </th>
                    <th className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {datalist.Name}
                    </th>
                    <th className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      <img
                        src={datalist.path}
                        alt={`Image ${index + 1}`}
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </th>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {datalist?.Status}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4 space-x-4">
                      {/* {datalist.Status === "Active" && (
                        <>
                          <Link to={"/admin/addfeedback/" + datalist.id}>
                            <i class="fas fa-edit"></i>
                          </Link>
                        </>
                      )}{" "} */}
                      {datalist.Status === "Active" && (
                        <>
                          <button onClick={() => inactiveImage(datalist.id)}>
                            <i class="fas fa-ban text-red-600"></i>
                          </button>
                        </>
                      )}
                      {datalist.Status === "Inactive" && (
                        <>
                          <button onClick={() => activateImage(datalist.id)}>
                            <i class="fas fa-check-circle"></i>
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        UserData={{
          imageData,
          setImageData,
          paginationData,
          setPaginationData,
        }}
      />

      <ToastContainer />
    </>
  );
};

export default Image;
