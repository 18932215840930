import axios from "axios";
const { APIURL } = require("global");

/**
 *this api use for to get menu of
 */
const userAdd = async (data) => {
  const addApi = APIURL + "users/register";
  return await fetch(addApi, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const userUpdate = async (data) => {
  const addApi = APIURL + "users/update";
  return await fetch(addApi, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const getUsersId = async (id) => {
  try {
    const URL = APIURL + `users/` + id;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

/**
 *this api use for to show UserList
 */

// eslint-disable-next-line no-undef
const getUsersList = async () => {
  const listApi = APIURL + "users/list";
  return await fetch(listApi);
};

const deleteUser = async (id) => {
  try {
    const URL = APIURL + "users/delete";
    return await axios.post(`${URL}`, {
      userID: id,
    });
  } catch (error) {
    return error.response;
  }
};

const activateUser = async (id) => {
  try {
    const URL = APIURL + "users/activate";
    return await axios.post(`${URL}`, {
      userID: id,
    });
  } catch (error) {
    return error.response;
  }
};

const deactivateUser = async (id) => {
  try {
    const URL = APIURL + "users/deactivate";
    return await axios.post(`${URL}`, {
      userID: id,
    });
  } catch (error) {
    return error.response;
  }
};

const getActiveUserList = async (list) => {
  try {
    const URL = APIURL + `users/activeList`;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

/**
 *this api use for to resetPassword
 */
// const resetPassword = async (data) => {
//   const resetPasswordApi = APIURL + "resetPassword";
//   return await fetch(resetPasswordApi, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   });
// };

// export { resetPassword, getUsersList, userAdd };
const authenticateLogin = async (data) => {
  try {
    const URL = APIURL + "login";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};
const authenticateForgot = async (data) => {
  try {
    const URL = APIURL + "admin/forgotPassword";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
const resetpassword = async (data) => {
  try {
    const URL = APIURL + "admin/resetPassword";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const getMyAccountDetails = async (data) => {
  try {
    const URL = APIURL + "users/myaccountdetail";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const userLogin = async (data) => {
  try {
    const URL = APIURL + "users/login";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const userForgetEmail = async (data) => {
  try {
    const URL = APIURL + "users/forgotPassword";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const usersResetPassword = async (data) => {
  try {
    const URL = APIURL + "users/resetPassword";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const ConnectWithUs = async (data) => {
  try {
    const URL = APIURL + "users/connectWithUs";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

export {
  deleteUser,
  activateUser,
  deactivateUser,
  userUpdate,
  userAdd,
  getUsersList,
  authenticateLogin,
  authenticateForgot,
  resetpassword,
  getMyAccountDetails,
  userForgetEmail,
  usersResetPassword,
  userLogin,
  getUsersId,
  ConnectWithUs,
};
