/**
 *this api use for to show footer-configuration
 */

import axios from "axios";

const { APIURL } = require("global");

const getconfigurationActiveList = async () => {
  const listApi = APIURL + "configurations/activeList";
  return await fetch(listApi);
};

const getconfigurationList = async () => {
  const listApi = APIURL + "configurations/list";
  return await fetch(listApi);
};

const getConfigurationDetail = async (id) => {
  const listApi = APIURL + "configurations/" + id;
  return await fetch(listApi);
};

const updateConfiguration = async (data) => {
  try {
    const URL = APIURL + "configurations/update";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const dynamicTraction = async () => {
  try {
    const URL = APIURL + "configurations/dynamicTraction";
    return await axios.get(`${URL}`);
  } catch (error) {
    return error.response;
  }
};

const dynamicInvestmentMarathon = async () => {
  try {
    const URL = APIURL + "configurations/dynamicInvestmentMarathon";
    return await axios.get(`${URL}`);
  } catch (error) {
    return error.response;
  }
};

const dynamicAcceleration = async () => {
  try {
    const URL = APIURL + "configurations/dynamicAcceleration";
    return await axios.get(`${URL}`);
  } catch (error) {
    return error.response;
  }
};

export {
  getconfigurationList,
  getconfigurationActiveList,
  getConfigurationDetail,
  updateConfiguration,
  dynamicTraction,
  dynamicInvestmentMarathon,
  dynamicAcceleration,
};
