/**
*this api use for to show content for privacy-policy
*/

const { APIURL } = require("global")

const getContentPrivacyPolicy = async() =>{
    const listApi = APIURL + "content/list";
    return await fetch (listApi);
}

export{getContentPrivacyPolicy}