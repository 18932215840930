import ScrollToTop from "components/front/ScrollToTop";
import React, { useState } from "react";
import { createApplication } from "services/application.service";

function BusinessPlan({ formData }) {
  const user = JSON.parse(localStorage.getItem("loggedUser"))
  const handleSubmit = (e) => {
    e.preventDefault();
    var totalRevenueEarned =
      formData.execution.totalRevenueEarned?.aggregate?.replace(
        "~~",
        "~null~"
      ) +
      "~" +
      formData.execution.totalRevenueEarned?.lastYear?.replace("~~", "~null~") +
      "~" +
      formData.execution.totalRevenueEarned?.lastMonth?.replace(
        "~~",
        "~null~"
      ) +
      "~" +
      formData.execution.totalRevenueEarned?.likelyHappen?.replace(
        "~~",
        "~null~"
      );
    var totalExpensesIncurred =
      formData.execution.totalExpensesIncurred?.aggregate?.replace(
        "~~",
        "~null~"
      ) +
      "~" +
      formData.execution.totalExpensesIncurred?.lastYear?.replace(
        "~~",
        "~null~"
      ) +
      "~" +
      formData.execution.totalExpensesIncurred?.lastMonth?.replace(
        "~~",
        "~null~"
      ) +
      "~" +
      formData.execution.totalExpensesIncurred?.likelyHappen?.replace(
        "~~",
        "~null~"
      );
    var salesAndMarketing =
      formData.execution.salesAndMarketing?.aggregate?.replace("~~", "~null~") +
      "~" +
      formData.execution.salesAndMarketing?.lastYear?.replace("~~", "~null~") +
      "~" +
      formData.execution.salesAndMarketing?.lastMonth?.replace("~~", "~null~") +
      "~" +
      formData.execution.salesAndMarketing?.likelyHappen?.replace(
        "~~",
        "~null~"
      );
    var uniqueUserFree =
      formData.execution.uniqueUserFree?.aggregate?.replace("~~", "~null~") +
      "~" +
      formData.execution.uniqueUserFree?.lastYear?.replace("~~", "~null~") +
      "~" +
      formData.execution.uniqueUserFree?.lastMonth?.replace("~~", "~null~") +
      "~" +
      formData.execution.uniqueUserFree?.likelyHappen?.replace("~~", "~null~");
    var uniqueUserPaid =
      formData.execution.uniqueUserPaid?.aggregate?.replace("~~", "~null~") +
      "~" +
      formData.execution.uniqueUserPaid?.lastYear?.replace("~~", "~null~") +
      "~" +
      formData.execution.uniqueUserPaid?.lastMonth?.replace("~~", "~null~") +
      "~" +
      formData.execution.uniqueUserPaid?.likelyHappen?.replace("~~", "~null~");
    var uniqueCustomersFree =
      formData.execution.uniqueCustomersFree?.aggregate?.replace(
        "~~",
        "~null~"
      ) +
      "~" +
      formData.execution.uniqueCustomersFree?.lastYear?.replace(
        "~~",
        "~null~"
      ) +
      "~" +
      formData.execution.uniqueCustomersFree?.lastMonth?.replace(
        "~~",
        "~null~"
      ) +
      "~" +
      formData.execution.uniqueCustomersFree?.likelyHappen?.replace(
        "~~",
        "~null~"
      );
    var uniqueCustomersPaid =
      formData.execution.uniqueCustomersPaid?.aggregate?.replace(
        "~~",
        "~null~"
      ) +
      "~" +
      formData.execution.uniqueCustomersPaid?.lastYear?.replace(
        "~~",
        "~null~"
      ) +
      "~" +
      formData.execution.uniqueCustomersPaid?.lastMonth?.replace(
        "~~",
        "~null~"
      ) +
      "~" +
      formData.execution.uniqueCustomersPaid?.likelyHappen?.replace(
        "~~",
        "~null~"
      );
    var transactions =
      formData.execution.transactions?.aggregate?.replace("~~", "~null~") +
      "~" +
      formData.execution.transactions?.lastYear?.replace("~~", "~null~") +
      "~" +
      formData.execution.transactions?.lastMonth?.replace("~~", "~null~") +
      "~" +
      formData.execution.transactions?.likelyHappen?.replace("~~", "~null~");
    var obj = formData.dashboardData;
    obj.totalRevenueEarned = totalRevenueEarned;
    obj.totalExpensesIncurred = totalExpensesIncurred;
    obj.salesAndMarketing = salesAndMarketing;
    obj.uniqueUserFree = uniqueUserFree;
    obj.uniqueUserPaid = uniqueUserPaid;
    obj.uniqueCustomersFree = uniqueCustomersFree;
    obj.uniqueCustomersPaid = uniqueCustomersPaid;
    obj.transactions = transactions;
    obj.formStatus = "businessSubmit"

    createApplication(obj).then((response)=>{
      console.log(response)
    })
    formData.handleSecondTabs(e, "profile")
  };

  return (
    <>
      <ScrollToTop>
        <div className="tab_content">
          <form className="business_plan" onSubmit={handleSubmit}>
            <div className="form_group">
              <h4>
                <img src="images/Dashboard/dollar.svg" alt="" />
                Problem statement – Core user need for which user will be
                willing to pay
              </h4>
              <div className="inner">
                <label>
                  Problem statement<span>*</span>
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      problemStatement: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
            </div>
            <div className="form_group">
              <h4>
                <img src="images/Dashboard/dollar.svg" alt="" />
                Solution – Solution idea to address the problem statement
              </h4>
              <div className="inner">
                <label>
                  Solution – Core idea to solve the problem
                  <span>*</span>
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      solution: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div className="inner">
                <label>
                  Product description- Key product modules/offerings which as a
                  whole will offer a solution to the problem<span>*</span>
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      productDescription: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div className="inner">
                <label>
                  User Journey - Steps which the user goes through as he/she
                  uses the Product
                  <span>*</span>
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      userJourney: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div className="inner">
                <label>
                  Validation - Any Proof that the idea will work – Successful
                  example, Industry parallel, Any research, etc<span>*</span>
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      validation: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
            </div>
            <div className="form_group">
              <h4>
                <img src="images/Dashboard/dollar.svg" alt="" />
                Addressable market size (INR Crores) – Maximum annual revenue
                possible, assuming 100% Market Share
              </h4>
              <div className="inner">
                <label>
                  Target Group – Typical profile (persona) of the Target Group
                  (TG) of users who are likely to buy the offering
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      targetGroup: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <ul>
                <li>
                  <label>Maximum Annual TG users:</label>
                  <input
                    type="text"
                    name=""
                    onChange={(e) => {
                      formData.setDashboardData({
                        ...formData.dashboardData,
                        annualUser: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <label>
                    Likely ARPU (Annual Revenue Per User): for your offering:
                  </label>
                  <input
                    type="text"
                    name=""
                    placeholder="INR"
                    onChange={(e) => {
                      formData.setDashboardData({
                        ...formData.dashboardData,
                        annualRevenue: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <label>
                    Addressable Market size (Maximum Annual TG users X likely
                    ARPU):
                  </label>
                  <input
                    type="text"
                    name=""
                    placeholder="INR In Lacs"
                    onChange={(e) => {
                      formData.setDashboardData({
                        ...formData.dashboardData,
                        max_ARPU: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
              <div className="inner">
                <label>
                  Key assumptions: To arrive at Addressable Market Size.
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      assumption: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
            </div>
            <div className="form_group">
              <h4>
                <img src="images/Dashboard/dollar.svg" alt="" />
                Competition – Likely competitors & sources of long term
                competitive advantage
              </h4>
              <div className="inner">
                <label>
                  Competitors – Serving the customer need with a similar product
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      competitive: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div className="inner">
                <label>
                  Differentiation – Unique selling proposition which
                  differentiate you from Competitors.
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      differentiation: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div className="inner">
                <label>
                  Long term competitive advantage – (MOAT)- 2-3 advantages you
                  will achieve in 5-10 years, due to which no one can put you
                  out of business easily
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      longTermCompetitiveAdvantage: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
            </div>
            <div className="form_group">
              <h4>
                <img src="images/Dashboard/dollar.svg" alt="" />
                Business Model – Key tenets about how is Business is run
              </h4>
              <div className="inner">
                <label>
                  Revenue model / Pricing Structure – Key revenue sources and
                  pricing levels
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      revenueModel: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div className="inner">
                <label>
                  Production method – Methods to create different product
                  modules/offerings be created? For e.g.: Content, Website, etc
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      productionMethod: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div className="inner">
                <label>
                  Sales / Growth method – Channels to be used and method to sell
                  through each channel
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      growthMethod: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div className="inner">
                <label>Servicing method – Channels to service the users</label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      serviceMethod: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
              <div className="inner">
                <label>
                  Renewal method – Methods to make customers buy the product
                  next one
                </label>
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      renewalMethod: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
            </div>
            <div className="form_group">
              <h4>
                Execution – Implementation so far (Only for ventures which
                beyond Idea stage)
              </h4>
              <table
                data-dynamicrows
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Aggregate-Since Inception</th>
                    <th>Last Year 2020-21</th>
                    <th>Last Month</th>
                    <th>Likely to happen this year (2021-22)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label>Total Revenue Earned (INR Lacs)</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          formData.setExecution({
                            ...formData.execution,
                            totalRevenueEarned: {
                              ...formData.execution.totalRevenueEarned,
                              aggregate: e.target.value,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          formData.setExecution({
                            ...formData.execution,
                            totalRevenueEarned: {
                              ...formData.execution.totalRevenueEarned,
                              lastYear: e.target.value,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          formData.setExecution({
                            ...formData.execution,
                            totalRevenueEarned: {
                              ...formData.execution.totalRevenueEarned,
                              lastMonth: e.target.value,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          formData.setExecution({
                            ...formData.execution,
                            totalRevenueEarned: {
                              ...formData.execution.totalRevenueEarned,
                              likelyHappen: e.target.value,
                            },
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Total Expenses Incurred (INR Lacs)</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          formData.setExecution({
                            ...formData.execution,
                            totalExpensesIncurred: {
                              ...formData.execution.totalExpensesIncurred,
                              aggregate: e.target.value,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          formData.setExecution({
                            ...formData.execution,
                            totalExpensesIncurred: {
                              ...formData.execution.totalExpensesIncurred,
                              lastYear: e.target.value,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          formData.setExecution({
                            ...formData.execution,
                            totalExpensesIncurred: {
                              ...formData.execution.totalExpensesIncurred,
                              lastMonth: e.target.value,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          formData.setExecution({
                            ...formData.execution,
                            totalExpensesIncurred: {
                              ...formData.execution.totalExpensesIncurred,
                              likelyHappen: e.target.value,
                            },
                          })
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label>Sales & Marketing Expense (INR Lacs)</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            salesAndMarketing: {
                              ...formData.execution.salesAndMarketing,
                              aggregate: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            salesAndMarketing: {
                              ...formData.execution.salesAndMarketing,
                              lastYear: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            salesAndMarketing: {
                              ...formData.execution.salesAndMarketing,
                              lastMonth: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            salesAndMarketing: {
                              ...formData.execution.salesAndMarketing,
                              likelyHappen: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        Unique User - Free (End user of offering, eg. students)
                      </label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueUserFree: {
                              ...formData.execution.uniqueUserFree,
                              aggregate: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueUserFree: {
                              ...formData.execution.uniqueUserFree,
                              lastYear: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueUserFree: {
                              ...formData.execution.uniqueUserFree,
                              lastMonth: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueUserFree: {
                              ...formData.execution.uniqueUserFree,
                              likelyHappen: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        Unique User - Paid (End user of offering, eg. students)
                      </label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueUserPaid: {
                              ...formData.execution.uniqueUserPaid,
                              aggregate: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueUserPaid: {
                              ...formData.execution.uniqueUserPaid,
                              lastYear: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueUserPaid: {
                              ...formData.execution.uniqueUserPaid,
                              lastMonth: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueUserPaid: {
                              ...formData.execution.uniqueUserPaid,
                              likelyHappen: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        Unique Customers - Free (Those who are willing to pay
                        for the product, eg. parents/schools)
                      </label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueCustomersFree: {
                              ...formData.execution.uniqueCustomersFree,
                              aggregate: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueCustomersFree: {
                              ...formData.execution.uniqueCustomersFree,
                              lastYear: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueCustomersFree: {
                              ...formData.execution.uniqueCustomersFree,
                              lastMonth: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueCustomersFree: {
                              ...formData.execution.uniqueCustomersFree,
                              likelyHappen: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        Unique Customers - Paid (Those who are willing to pay
                        for the product, eg. parents/schools)
                      </label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueCustomersPaid: {
                              ...formData.execution.uniqueCustomersPaid,
                              aggregate: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueCustomersPaid: {
                              ...formData.execution.uniqueCustomersPaid,
                              lastYear: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueCustomersPaid: {
                              ...formData.execution.uniqueCustomersPaid,
                              lastMonth: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            uniqueCustomersPaid: {
                              ...formData.execution.uniqueCustomersPaid,
                              likelyHappen: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        No. of Transactions (Including Repeat Purchase)
                      </label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            transactions: {
                              ...formData.execution.transactions,
                              aggregate: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            transactions: {
                              ...formData.execution.transactions,
                              lastYear: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            transactions: {
                              ...formData.execution.transactions,
                              lastMonth: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          formData.setExecution({
                            ...formData.execution,
                            transactions: {
                              ...formData.execution.transactions,
                              likelyHappen: e.target.value,
                            },
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="form_group">
              <h4>
                <img src="images/Dashboard/dollar.svg" alt="" />
                Right to Exist – Why do you think the team will be able to
                create successful business?
              </h4>
              <div className="inner">
                <textarea
                  maxLength="1500"
                  placeholder="Max 1500 Characters"
                  onChange={(e) => {
                    formData.setDashboardData({
                      ...formData.dashboardData,
                      rightToExist: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
            </div>
            <div className="form_group">
              <h4>
                <img src="images/Dashboard/dollar.svg" alt="" />
                Funding
              </h4>
              <ul>
                <li>                 
                  <label>Ask</label>
                  <input
                    type="text"
                    name=""
                    placeholder="INR In Lacs"
                    onChange={(e) => {
                      formData.setDashboardData({
                        ...formData.dashboardData,
                        fundingAsk: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <label>Expected Valuation</label>
                  <input
                    type="text"
                    name=""
                    placeholder="INR In Lacs"
                    onChange={(e) => {
                      formData.setDashboardData({
                        ...formData.dashboardData,
                        fundingExpected: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
            <div className="form_group">
              <h4>
                <img src="images/Dashboard/dollar.svg" alt="" />
                Shareholding Structure
              </h4>
              <ul>
                <li>
                  <label>Total Investment so far (Founder)</label>
                  <input
                    type="text"
                    name=""
                    placeholder="INR In Lacs"
                    onChange={(e) => {
                      formData.setDashboardData({
                        ...formData.dashboardData,
                        shareholdingFounder: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <label>Total Investment so far (Other)</label>
                  <input
                    type="text"
                    name=""
                    placeholder="INR In Lacs"
                    onChange={(e) => {
                      formData.setDashboardData({
                        ...formData.dashboardData,
                        shareholdingOther: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
            <div className="form_group" id="last">
              <h4>
                <img src="images/Dashboard/dollar.svg" alt="" />
                Shareholding Structure
              </h4>
              <ul>
                <li>
                  <label>Total Investment so far (Founder)</label>
                  <input type="text" name="" placeholder="INR In Lacs" />
                  <input type="" name="" placeholder="%" />
                </li>
                <li>
                  <label>Total Investment so far (Other)</label>
                  <input type="text" name="" placeholder="INR In Lacs" />
                  <input type="" name="" placeholder="%" />
                </li>
              </ul>
            </div>
            <button
              className="update_plan head-cto"
              type="submit"
              // onClick={(e) => formData.handleSecondTabs(e, "profile")}
            >
              Next
            </button>
          </form>
        </div>
      </ScrollToTop>
    </>
  );
}

export default BusinessPlan;
