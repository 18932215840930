import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authenticateForgot } from "services/user.service";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const forgotInitialValues = {
    email: "",
  };
  const [forgot, setForgot] = useState(forgotInitialValues);

  const onValueChange = (e) => {
    setForgot({ ...forgot, [e.target.name]: e.target.value });
  };

  const forgotUser = async () => {
    if (forgot.email === "") {
      const notify = () =>
        toast("Credential is invalid, Please fill the correct Emailid!", {
          theme: "colored",
        });
      notify();

      return;
    }

    let response = await authenticateForgot(forgot);
    console.log(response);

    const adminid = response.data.result.adminid;

    console.log(adminid);

    const notify = () =>
      toast(
        "A code has been sent to your e-mail please check your e-mail , Click OK to move to Reset Password page",
        {
          theme: "colored",
        }
      );
    notify();

    localStorage.setItem("adminid", adminid);
    setTimeout(() => {
      navigate("/admin/reset");
    }, 2000);
  };

  const formSchema = Yup.object().shape({
    email: Yup.string().required("Invalid Email"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blue-950 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          >
            <h1 className="text-center text-white text-3xl mt-20 ">
              Amar Financial
            </h1>
          </div>

          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-700 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-white text-center mb-6  font-bold">
                      <h1 className="text-2xl">Forgot your Password?</h1>
                    </div>
                    <form>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-white text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <input
                          name="email"
                          required
                          {...register("email")}
                          className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          placeholder="Email"
                          onChange={(e) => onValueChange(e)}
                        />

                        <div className="invalid-feedback">
                          {errors.email?.message}
                        </div>
                      </div>

                      <div className="text-center mt-6">
                        <button
                          className="bg-green-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => {
                            forgotUser();
                          }}
                        >
                          Submit
                        </button>

                        <div className="flex flex-wrap mt-6 relative">
                          <div className="w-1/2">
                            <Link to="/admin">
                              <h1 className="text-center text-white font-bold">
                                Return to Login?
                              </h1>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
}
