import React from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  getportfolioDataByKey,
  getActivePortfolioList,
} from "services/portfolio.service";
import "./Portfolio.css";
import { useState, useEffect } from "react";
import { IMAGEURL } from "global";

function Portfolio() {
  const [key, setKey] = useState("");
  const [portfolioCategoryData, setPortfolioCategoryData] = useState([]);
  const [portfolioData, setPortfolioData] = useState([]);


  useEffect(() => {  
    getActivePortfolioList().then((res) => {
      console.log("gsfdhgfs", res);
      setPortfolioCategoryData(res.data.Result);
      setKey("all");
    });
  }, []);

  const handleChange = (k, e) => {
    setKey(k);
    if (k !== "all") {
      console.log("im here", k);
      let obj = {
        tabKey: k,
      };
      getportfolioDataByKey(obj).then((resp) => {
        console.log("gggggg", resp);
        setPortfolioData(resp.data.Result);
      });
    }
  };

  return (
    <>
      <section className="pg-portfolio">
        {/* banner */}
        <section className="ev-Banner_sec">
          <div className="d-flex flex-column justify-content-center">
            <div className="grid-50 coverup">
              <h1>Portfolios</h1>
            </div>
          </div>
        </section>
        {/* Tabs */}
        <div className="container">
          <div className="port-tabs">
            <Tabs
              defaultActiveKey="all"
              id="fill-tab-example"
              activeKey={key}
              onSelect={(k, e) => {
                handleChange(k, e);
              }}
              className="portfolio-tabs"
              fill
            >
              <Tab eventKey="all" title="All">
                <div className="portfolio-tabs-content">
                  {portfolioCategoryData?.length > 0
                    ? portfolioCategoryData?.map((portfolio, index) => (
                        <>
                          <div className="port-card">
                            <div className="port-card-inner">
                              <figure className="port-card-front">
                                <img
                                  src={`${IMAGEURL}${portfolio.Image}`}
                                  className="testi-img"
                                  alt=""
                                />
                              </figure>
                              <div className="port-card-back">
                                <div>
                                  <p>{portfolio.Title}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    : ""}
                </div>
              </Tab>
              <Tab className="" eventKey="investment" title="Investment">
                <div className="portfolio-tabs-content">
                  {portfolioData?.length > 0
                    ? portfolioData?.map((portfolio) => (
                        <>
                          <div className="port-card">
                            <div className="port-card-inner">
                              <figure className="port-card-front">
                                <img
                                  src={`${IMAGEURL}${portfolio.Image}`}
                                  className="testi-img"
                                  alt=""
                                />
                              </figure>
                              <div className="port-card-back">
                                <div>
                                  <p>{portfolio.Title}</p>
                                </div>
                              </div>
                            </div>
                          </div>

                        </>
                      ))
                    : ""}
                </div>
              </Tab>

              <Tab className="" eventKey="accelerated" title="Accelerated">
                <div className="portfolio-tabs-content">
                  {portfolioData?.length > 0? portfolioData?.map((portfolio, index) => (
                    
                        <>
                          <div className="port-card">
                            <div className="port-card-inner">
                              <figure className="port-card-front">
                                <img
                                  src={`${IMAGEURL}${portfolio.Image}`}
                                  className="testi-img"
                                  alt=""
                                />
                              </figure>
                              <div className="port-card-back">
                                <div>
                                  <p>{portfolio.Title}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    : ""}
                </div>
              </Tab>
              
              <Tab className="" eventKey="partnerships" title="Partnerships">
                <div className="portfolio-tabs-content">
                  {portfolioData?.length > 0
                    ? portfolioData?.map((portfolio, index) => (
                        <>
                          <div className="port-card">
                            <div className="port-card-inner">
                              <figure className="port-card-front">
                                <img
                                  src={`${IMAGEURL}${portfolio.Image}`}
                                  className="testi-img"
                                  alt=""
                                />
                              </figure>
                              <div className="port-card-back">
                                <div>
                                  <p>{portfolio.Title}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    : ""}
                </div>
              </Tab>
              
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
}

export default Portfolio;
