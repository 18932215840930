import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./Banner.css";
import { getBannerImage } from "services/image.service";
import image1 from "../Banner/image/image1.jpg";
import image2 from "../Banner/image/image2.jpg";
import image3 from "../Banner/image/image3.jpg";

function Banner() {
  const [image, setImage] = useState([]);

  useEffect(() => {
    getBannerImage()
      .then((response) => {
        setImage(response.data.Result);
      })
      .catch((error) => {
        console.error("Error fetching role data:", error);
      });
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000, // Change image every 3 seconds
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const index = 1;
  return (
    <>
      <section className="pg-home">
        {/* Banner Section */}

        {/* <div className="banner">
          <Slider {...settings}>
            {image?.map((datalist, index) => {
              return (
                <div key={index} className="slide">
                  <img src={datalist.path} alt={`Image ${index + 1}`} />
                </div>
              );
            })}
          </Slider>
        </div> */}
        <div className="banner">
          <Slider {...settings}>
            <div key={index} className="slide">
              <img src={image1} alt={`Image ${index + 1}`} />
            </div>{" "}
            <div key={index} className="slide">
              <img src={image2} alt={`Image ${index + 2}`} />
            </div>{" "}
            <div key={index} className="slide">
              <img src={image3} alt={`Image ${index + 3}`} />
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
}

export default Banner;
