// import React from "react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { UserAddView } from "view/admin/userAddView";

export default function AddUsermanagement() {
  // const [param, setUsers] = useState([]);
  const param = useParams();
  console.log(param);

  return <UserAddView param={param}></UserAddView>;
}
