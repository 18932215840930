
//////////////////
import axios from "axios";
/**
 *this api use for to show testimonialList
 */
const { APIURL } = require("global");
// eslint-disable-next-line no-undef


// const getSubscriptionList = async () => {
//   const listApi = APIURL + "subscription/list";
//   return await fetch(listApi);
// };



const getSubscriptionList = async (list) => {
  try {
    const URL = APIURL + `subscription/list`;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};




const getActiveSubscriptionList = async () => {
  const listApi = APIURL + "subscription/activeList";
  return await fetch(listApi);
};


// const GetTestmonialList = async (list) => {
//   try {
//     const URL = APIURL + `testimonial/list`;
//     return await axios.get(`${URL}`);
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };


const GetSubscriptionByID = async (id) => {

  try {
    const URL = APIURL + `subscription/` + id;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const CreateSubscription = async (data) => {
  try {
    const URL = APIURL + "subscription/create";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const UpdateSubscription = async (data) => {
  try {
    const URL = APIURL + "subscription/update";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};


const ActivateSubscription = async (id)=> {
 
  try {
    const URL = APIURL + "subscription/activate";
    return await axios.post(`${URL}`,  {
      subscriptionID: id,
    });
  } catch (error) {
    return error.response;
  }
};



const DeactivateSubscription = async (id) => {

  try {
    const URL = APIURL + "subscription/deactivate";
    return await axios.post(`${URL}`, {
      subscriptionID: id,
    });
  } catch (error) {
    return error.response;
  }
};



const DeleteSubscription = async (id) => {

  try {
    const URL = APIURL + "subscription/delete";
    return await axios.post(`${URL}`, {
      subscriptionID: id,
    });
  } catch (error) {
    return error.response;
  }
};

const ActiveSubscription = async (id)=> {
 
  try {
    const URL = APIURL + "subscription/activeList";
    return await axios.get(`${URL}`);
  } catch (error) {
    return error.response;
  }
};

export {
  getActiveSubscriptionList,
  GetSubscriptionByID,
  // GetTestmonialList,
  CreateSubscription,
   UpdateSubscription,
  ActivateSubscription,
  DeactivateSubscription,
  DeleteSubscription,
  ActiveSubscription,
  /////////
  getSubscriptionList
};
