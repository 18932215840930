import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { usersResetPassword } from "services/user.service";
// import { number } from "yup";


// import { useLocation } from "react-router-dom";




function ResetPass() {
  // const location=useLocation();

  const  navigate = useNavigate();
  const ResetInitialValues = {
    code: "",
    password: "",
    confirmPassword:"",
    userID:`${localStorage.getItem("userID")}`
  
  }

  const [error,showError]= useState(false);
  const [reset,setReset]= useState(ResetInitialValues);
  const [confirm,setConfirm]=useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const onInputChange =(e)=>{

               setReset({...reset,[e.target.name]:e.target.value})
    }

const resetPassword=async()=>{
  //  console.log(reset.password);
  //  console.log(reset.confirmPassword);
  
 if(reset.password===reset.confirmPassword) {
  
  let response= await usersResetPassword(reset);
  console.log(response);
      if(response.data.error===true) {
            showError(true);
            setErrorMessage(response.data.message)
             }
      else      
            {
              showError(false);
              navigate("/login")
            }
}
  else {
    setConfirm(true);
    return "your password and confirmPassword doesn't match"
  }

 
}

  return (
    <>
      <section className="pg-forgot-pass">
        <div className="form sign-in">
          <h2  style={{ color: "green" }} className="font-['Poppins'] text-4xl">
            Reset Password
          </h2>
          {/* <h1>{localStorage.getItem("userID")}</h1> */}
          {/* {console.log(location.state.userID)} */}
          {/* {console.log(localStorage.getItem("userID"))} */}
          <label>
          {error && <p className=" text-red-600 text-center " >{errorMessage}</p>}
            <span >Enter Code</span>
            <input placeholder="Enter only Number/digits" name="code" onChange={(e) => {
             
             if(/^\d+$/.test(e.target.value)){

               onInputChange(e) 
             }
             else {
              alert("put only Number in Code")
             }
             
           }} type="text" />
          </label>
          <label>
            <span>Enter New Password</span>
            <input name="password" onChange={(e) => onInputChange(e)} type="password" minLength={6} maxLength={10} />
          </label>
          <label>
            <span>Confirm New Password</span>
            <input  name="confirmPassword" onChange={(e) => onInputChange(e)} type="password" minLength={6} maxLength={10}/>
          </label>

          {confirm && <p className=" text-red-600 text-center " >your password and confirmPassword doesn't match</p>}

          <button type="button" onClick={()=>resetPassword()} className="forgot-sendbtn">
            Send
          </button>
          {/* <div className="forgotPass-or">
            <div className="or-block">
              <p>OR</p>
              <hr />
            </div>
            <p>Don't have an account?</p>
            <Link to="/login">
              <button className="signup-btn">Sign Up</button>
            </Link>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default ResetPass;
