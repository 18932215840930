import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleKeyPressforSpecialCharacters } from "components/RegexValidation";
import {
  GetTimelineListByID,
  CreateTimeline,
  UpdateTimeline,
} from "services/timeline.service";
import { IMAGEURL } from "global";

export const TimelineAdd = () => {
  const param = useParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [createby, setCreateby] = useState(user.AdminID);
  const [timelineID, setTimelineID] = useState("");
  const [size, setSize] = useState("");
  const [base64, setBase64] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [disable, setDisable] = useState(false);

  const datePickerRef = useRef(null);

  const openDatePicker = () => {
    datePickerRef.current.focus();
  };

  useEffect(() => {
    if (param && typeof param.id !== "undefined") {
      GetTimelineListByID(param.id).then((response) => {
        let timelineDetail = response.data.Result[0];
        setTimelineID(timelineDetail.TimelineID);
        setDate(timelineDetail.Date);
        setTitle(timelineDetail.Title);
        setDescription(timelineDetail.Description);
        setImagePreview(timelineDetail.Image);
      });
    }
  }, []);

  const handleUpload = (e) => {
    let file = e.target.files[0];
    let ext = file !== undefined ? file.name?.split(".")[1] : "";

    if (
      ext.toLowerCase() === "jpeg" ||
      ext.toLowerCase() === "jpg" ||
      ext.toLowerCase() === "png"
    ) {
      if (file) {
        const reader = new FileReader();
        setSize(file.size);
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      }
    } else if (ext === "") {
      return false;
    } else {
      e.preventDefault();
      setBase64("");
      setImagePreview("");
      toast.error("Image must be jpg, jpeg and png extension");
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64(btoa(binaryString));
    setImagePreview("data:image/png;base64," + btoa(binaryString));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (title.trim() === "" || description.trim() === "") {
      // Handle the case when the input field is blank
      console.log("Input field cannot be blank");
      toast.warn("Input field cannot be blank");
      return;
    }

    const timelineData = {
      id: e.target.id.value,
      title: title.replace("'", "''"),
      description: description.replace("'", "''"),
      date: date,
      // image: base64,
      image: param.id ? (base64 !== "" ? base64 : "") : base64,
      createby: createby,
    };
    setDisable(true);

    if (e.target.id.value === "") {
      CreateTimeline(timelineData).then((response) => {
        if (response.data.Status === "Success") {
          toast.success(response.data.Message);
          setTimeout(() => {
            navigate("/admin/timeline");
          }, 1000);
        } else {
          toast.error(response.data.Message);
        }
      });
    } else {
      UpdateTimeline(timelineData).then((response) => {
        if (response.data.Status === "Success") {
          toast.success(response.data.Message);
          setTimeout(() => {
            navigate("/admin/timeline");
          }, 1000);
        } else {
          toast.error(response.data.Message);
        }
      });
    }
    // function openDatePicker() {
    //   var datePickerInput = document.getElementById("datePickerInput");
    //   datePickerInput.click();
    // }
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                {param.id ? "Edit" : "Add New"} Timeline
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={handleSubmit}>
                    <input type="hidden" name="createby" value={createby} />
                    <input
                      type="hidden"
                      name="id"
                      value={param.id ? param.id : ""}
                    />
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Title <span className="text-red-500">*</span>
                      </label>
                      <input
                        value={title}
                        type="text"
                        autoFocus
                        name="title"
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Title"
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.preventDefault();
                          } else {
                            setTitle(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Description <span className="text-red-500">*</span>
                      </label>
                      <input
                        value={description}
                        type="text"
                        name="Description"
                        required
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                      }`}
                        placeholder="Description"
                        // onChange={(e) => setDescription(e.target.value)}
                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.preventDefault();
                          } else {
                            setDescription(e.target.value);
                          }
                        }}
                      />
                      <label
                        className="block uppercase my-3 text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Date <span className="text-red-500">*</span>
                      </label>
                      {/* <input
                        value={date}
                        type="date"
                        id="datePickerInput"
                        ref={datePickerRef}
                        name="Date"
                        required
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                      }`}
                        placeholder="Date"
                        onChange={(e) => setDate(e.target.value)}
                      /> */}
                      <input
                        value={date}
                        type="date"
                        id="datePickerInput"
                        ref={datePickerRef}
                        onFocus={openDatePicker}
                        name="Date"
                        required
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Date"
                        onChange={(e) => setDate(e.target.value)}
                      />
                      <div className="relative w-full mb-3 py-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-Name"
                        >
                          Image
                          {param.id ? (
                            ""
                          ) : (
                            <span className="text-red-500">*</span>
                          )}
                        </label>

                        <input
                          type="file"
                          name="image"
                          required={param.id ? false : true}
                          className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                      }`}
                          accept=".jpeg,.jpg,.png"
                          onChange={handleUpload}
                        />
                        {base64 === "" ? (
                          <img
                            src={`${IMAGEURL}${imagePreview}`}
                            width="100"
                            alt=""
                          />
                        ) : (
                          <img src={`${imagePreview}`} width="100" alt="" />
                        )}
                      </div>

                      <div className="text-left mt-6">
                        <button
                          className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit"
                          disabled={disable}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default TimelineAdd;
