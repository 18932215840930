import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { handleKeyPressforSpecialCharacters } from "components/RegexValidation";
import {
  updateportfolio,
  createportfolio,
  deleteportfolio,
  getportfolioId,
  getActiveListPortfolio,
} from "services/adminPortfolio.service";
const { IMAGEURL } = require("global");
// import { userNameRegex } from "components/RegexValidation";

export const Addportfolio = () => {
  const param = useParams();
  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [profilePicPreview, setProfilePicPreview] = useState("");
  const [base64, setBase64] = useState("");
  const [size, setSize] = useState("");
  const [PortfolioID, setPortfolioID] = useState("");
  const [createby, setCreateby] = useState(user.AdminID);
  const [PortfolioCategory, setCategory] = useState("");
  const [disable, setDisable] = useState(false);

  const uploadImage = (e) => {
    let file = e.target.files[0];
    let ext = file !== undefined ? file.name?.split(".")[1] : "";

    if (
      ext.toLowerCase() === "jpeg" ||
      ext.toLowerCase() === "jpg" ||
      ext.toLowerCase() === "png"
    ) {
      if (file) {
        const reader = new FileReader();
        setSize(file.size);
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      }
    } else if (ext === "") {
      return false;
    } else {
      setImagePreview("");
      toast.error("Image must be jpg, jpeg and png extension");
      return false;
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64(btoa(binaryString));
    setImagePreview("data:image/png;base64," + btoa(binaryString));
  };

 
  useEffect(() => {
    if (typeof param.id !== "undefined") {
      getportfolioId(param.id).then((response) => {
        let portfolioDetail = response.data.Result[0];
        setPortfolioID(portfolioDetail.PortfolioID);
        setCategory(portfolioDetail.PortfolioCategory);
        setImagePreview(portfolioDetail.Image);
        setTitle(portfolioDetail.Title);
      });
    }
  }, []);

  const addPortfolio = async (e) => {
    e.preventDefault();

    if (title.trim() === '') {
      // Handle the case when the input field is blank
      toast.warn("Input field cannot be blank");
      return;
    }

    const blog = {
      portfolioID: e.target.id.value,
      title: title.split("'").join("''"),
      image: base64,
      createBy: createby,
      portfolioCategory: PortfolioCategory,
    };

    if (e.target.id.value === "") {
      createportfolio(blog).then((data) => {
        if (data.data.Status === "Success") {
          toast.success(data.data.Message);
          setDisable(true);
          setTimeout(() => {
            navigate("/admin/portfoliolist");
          }, 2000);
        } else {
          toast.error(data.data.message);
        }
      });
    } else {
      updateportfolio(blog).then((data) => {
        if (data.data.Status === "Success") {
          toast.success(data.data.Message);
          setDisable(true);
          setTimeout(() => {
            navigate("/admin/portfoliolist");
          }, 2000);
        } else {
          toast.error(data.data.message);
        }
      });
    }
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                {param.id ? "Edit" : "Add New"} Portfolio
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={addPortfolio}>
                    <input type="hidden" name="id" value={PortfolioID} />
                    <input type="hidden" name="createby" value={createby} />

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Portfolio Category
                      </label>
                      <select
                        name="portfolioCategory"
                        value={PortfolioCategory}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value="">-- Select --</option>
                        <option value="Investment">Investment</option>
                        <option value="Accelerated">Accelerated</option>
                        <option value="Partnerships">Partnerships</option>
                      </select>
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        required
                        value={title}
                        // maxLength={20}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Title"
                        // onChange={handleInputChange}
                        disabled={disable}
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            //  setNameError(false);
                            e.preventDefault();
                          } else {
                            //  setNameError(false);
                            setTitle(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Upload Image
                      </label>
                      <input
                        type="file"
                        name="image"
                        required={param.id ? false : true}
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                        }`}
                        accept=".jpeg,.jpg,.png"
                        onChange={uploadImage}
                      />
                      {/* {imagePreview !== "" && (
                        <img src={image} width="100" alt="" />
                      )} */}
                      {base64 === "" ? (
                        <img
                          src={`${IMAGEURL}${imagePreview}`}
                          width="100"
                          alt=""
                        />
                      ) : (
                        <img src={`${imagePreview}`} width="100" alt="" />
                      )}
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                        disabled={disable}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default Addportfolio;
