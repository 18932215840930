import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getApplicationList,
  applicationReview,
  applicationAccept,
  applicationReject,
} from "services/application.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { data } from "autoprefixer";
import Pagination from "components/admin/Admin-Pagination/Pagination";

const Application = () => {
  const [applicationData, setApplicationData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);

  useEffect(() => {
    getApplicationDataList();
  }, []);

  const getApplicationDataList = () => {
    getApplicationList().then((response) => {
      setApplicationData(response.data.Result);
    });
  };

  const appAccepted = (applicationDatas) => {
    const obj = {
      id: applicationDatas.ApplicationID,
      userID: applicationDatas.UserID,
    };
    applicationAccept(obj).then((response) => {
      if (
        response.data.Status === "Success" &&
        response.data.ErrorCode === "200"
      ) {
        const notify = () => {
          toast.success(response.data.Message);
        };
        getApplicationDataList();
        notify();
      } else {
        const notify = () => {
          toast.error(response.data.message);
        };
        notify();
      }
    });
  };

  const appRejected = (applicationData) => {
    const obj = {
      id: applicationData?.ApplicationID,
      userID: applicationData?.UserID,
    };
    applicationReject(obj).then((response) => {
      if (
        response.data.Status === "Success" &&
        response.data.ErrorCode === "200"
      ) {
        const notify = () => {
          toast.success(response.data.Message);
        };
        notify();
        getApplicationDataList();
      } else {
        const notify = () => {
          toast.error(response.data.message);
        };
        notify();
      }
    });
  };

  const appReviewed = (applicationData) => {
    const obj = {
      id: applicationData?.ApplicationID,
      userID: applicationData?.UserID,
    };
    applicationReview(obj).then((response) => {
      if (
        response.data.Status === "Success" &&
        response.data.ErrorCode === "200"
      ) {
        const notify = () => {
          toast.success(response.data.Message);
        };
        notify();
        getApplicationDataList();
      } else {
        const notify = () => {
          toast.error(response.data.Message);
        };
        notify();
      }
    });
  };

  return (
    <>
      <div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
        <div className="mb-0 px-4 py-3 border-0 bg-slate-300">
          <div className="flex flex-wrap items-center">
            <div className="w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-black">Applications</h3>
            </div>
            <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
              <div className=" mt-32 sm:mt-0"></div>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr className="bg-blue-950">
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Brand Name
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Website
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Venture Stage
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Purpose
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Status
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData?.map((datalist, index) => {
                return (
                  <tr key={index}>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-normal p-2">
                      {datalist.BrandName}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-normal	 p-2">
                      {datalist.Website}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-normal	p-2">
                      {datalist.VentureStage}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-normal	p-2">
                      {datalist.Purpose}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-normal	p-2">
                      {datalist.CStatus}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-normal	p-2 space-x-2">
                      <Link
                        to={"/admin/addApplication/" + datalist.ApplicationID}
                      >
                        <i class="fas fa-eye"></i>
                      </Link>
                      {(datalist.CStatus === "Reviewed" ||
                        datalist.CStatus === "Rejected" ||
                        datalist.CStatus === "Submitted") && (
                        <>
                          <button onClick={(e) => appAccepted(datalist)}>
                            <i class="far fa-check-circle"></i>
                          </button>
                        </>
                      )}
                      {(datalist.CStatus === "Reviewed" ||
                        datalist.CStatus === "Accepted" ||
                        datalist.CStatus === "Submitted") && (
                        <>
                          <button onClick={(e) => appRejected(datalist)}>
                            <i class="far fa-times-circle"></i>
                          </button>
                        </>
                      )}
                      {(datalist.CStatus === "Accepted" ||
                        datalist.CStatus === "Rejected" ||
                        datalist.CStatus === "Submitted") && (
                        <>
                          <button onClick={(e) => appReviewed(datalist)}>
                            <i class="fas fa-glasses"></i>
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination UserData={{applicationData, setApplicationData, paginationData, setPaginationData}} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Application;
