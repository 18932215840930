import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./PrivacyPolicy.css";
import { getContentPrivacyPolicy } from "services/content.service";
import Banner from "../Banner/Banner";

function PrivacyPolicy() {
  const [contentPolicy, setContent] = useState([]);

  useEffect(() => {
    getContentPrivacyPolicy()
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setContent(data.Result);
      });
  }, []);

  return (
    <>
      <section className="pg-privacy">
        {/* Banner */}
        <Banner />
        <section className="privacy-content">
          {/* <Container className="py-[50px]">
            {contentPolicy.length > 0
              ? contentPolicy.map((privacyContent, index) => (
                  <>
                    <h2 className="text-2xl mb-2 font-bold">
                      {privacyContent.Title}
                    </h2>
                    <p className="mb-2">{privacyContent.Description}</p>
                  </>
                ))
              : ""}
          </Container> */}
          <Container className="py-[50px]">
            <>
              <h2 className="text-2xl mb-2 font-bold">
                When you use our services, you’re trusting us with your
                information. We understand this is a big responsibility and work
                hard to protect your information and put you in control.
              </h2>

              <div class="services">
                <p className="mb-2">
                  <h2>
                    We build a range of services that help millions of people
                    daily to explore and interact with the world in new ways.
                    Our services include:
                  </h2>
                  <ul>
                    <li>
                      Google apps, sites, and devices, like Search, YouTube, and
                      Google Home
                    </li>
                    <li>
                      Platforms like the Chrome browser and Android operating
                      system
                    </li>
                    <li>
                      Products that are integrated into third-party apps and
                      sites, like ads, analytics, and embedded Google Maps
                    </li>
                  </ul>
                  <p>
                    You can use our services in a variety of ways to manage your
                    privacy. For example, you can sign up for a Google Account
                    if you want to create and manage content like emails and
                    photos, or see more relevant search results. And you can use
                    many Google services when you’re signed out or without
                    creating an account at all, like searching on Google or
                    watching YouTube videos. You can also choose to browse the
                    web in a private mode, like Chrome Incognito mode. And
                    across our services, you can adjust your privacy settings to
                    control what we collect and how your information is used.
                  </p>
                  <p>
                    To help explain things as clearly as possible, we’ve added
                    examples, explanatory videos, and definitions for key terms.
                    And if you have any questions about this Privacy Policy, you
                    can
                  </p>
                </p>
              </div>
            </>
          </Container>
        </section>
      </section>
    </>
  );
}

export default PrivacyPolicy;
