// import { React } from "react";
// import ReactDOM from "react-dom/client";
// import "@fortawesome/fontawesome-free/css/all.min.css";
// import App from "App";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<App />);

// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "@fortawesome/fontawesome-free/css/all.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
