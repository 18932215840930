import React, { useEffect, useState } from "react";
import { getUsersList } from "services/user.service";
import { UserListView } from "view/admin/userListView";

export default function CardTable({ color }) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsersList()
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUsers(data.Result);
      });
  }, []);

  return <UserListView users={users}></UserListView>;
}
