import React from "react";
import { createPopper } from "@popperjs/core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom/dist";
import { NavLink } from "react-router-dom";

const UserDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("isLoggedIn");
    navigate("/admin/login");
  };

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-end",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <NavLink
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-10 h-10 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full border-[#fff] border-[1px]">
            {/* <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src="assets/img/team-1-800x800.jpg"
            /> */}
            <FontAwesomeIcon icon={faUser} className="text-xl" />
          </span>
        </div>
      </NavLink>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 !translate-y-[10vh]	!translate-x-[-5vh]"
        }
      >
        <p
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-[#000] "
          }
          style={{ cursor: "pointer" }}
          onClick={logout}
        >
          Logout
        </p>
      </div>
    </>
  );
};

export default UserDropdown;
