import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { handleKeyPressForNumbers } from "components/RegexValidation";
import {
  updateConfiguration,
  getListByConfigurationId,
} from "services/adminConfiguration.service";

export const AddConfiguartion = () => {
  const [error, setError] = useState(false);

  const param = useParams();
  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  const [address, setAddress] = useState("");
  const [emailID, setEmailID] = useState("");
  const [phone, setPhone] = useState("");
  const [accelerated, setAccelerated] = useState("");
  const [partnerships, setPartnerships] = useState("");
  const [investments, setInvestments] = useState("");
  const [investmentMarathonVersion, setInvestmentMarathonVersion] =
    useState("");
  const [investmentMarathonYear, setInvestmentMarathonYear] = useState("");
  const [equityData, setEquityData] = useState("");
  const [oneTimePaymentData, setOneTimePaymentData] = useState("");
  const [configurationID, setConfigurationID] = useState("");
  const [createby, setCreateby] = useState(user.AdminID);

  useEffect(() => {
    if (typeof param.id !== "undefined") {
      getListByConfigurationId(param.id).then((response) => {
        let configurationDetail = response.data.Result[0];
        setConfigurationID(configurationDetail.ConfigurationID);
        setAddress(configurationDetail.Address);
        setEmailID(configurationDetail.EmailID);
        setPhone(configurationDetail.Phone);
        setAccelerated(configurationDetail.Accelerated);
        setPartnerships(configurationDetail.Partnerships);
        setInvestments(configurationDetail.Investments);
        setInvestmentMarathonVersion(
          configurationDetail.InvestmentMarathonVersion
        );
        setInvestmentMarathonYear(configurationDetail.InvestmentMarathonYear);
        setOneTimePaymentData(configurationDetail.OneTimePayment);
        setEquityData(configurationDetail.Equity);
      });
    }
  }, []);

  const addConfiguration = async (e) => {
    e.preventDefault();

    const configuration = {
      id: e.target.id.value,
      address: address.trim(),
      phone: phone,
      emailID: emailID,
      accelerated: accelerated,
      partnerships: partnerships,
      investments: investments,
      investmentMarathonVersion: investmentMarathonVersion,
      investmentMarathonYear: investmentMarathonYear,
      equity: equityData,
      oneTimePayment: oneTimePaymentData,
      createby: createby,
    };

    updateConfiguration(configuration).then((data) => {
      if (data.data.Status === "Success") {
        toast.success(data.data.Message);

        setTimeout(() => {
          navigate("/admin/configurationlist");
        }, 2000);
      } else {
        toast.error("Inappropriate data, Please check your form");
        //  toast.error(data.data.errors);
      }
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                {param.id ? "Edit" : "Add New"} Configuration
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={addConfiguration}>
                    <input type="hidden" name="id" value={configurationID} />
                    <input type="hidden" name="createby" value={createby} />
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-address"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={address}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Address"
                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.preventDefault();
                          } else {
                            setAddress(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        Email ID
                      </label>
                      <input
                        type="email"
                        name="email"
                        required
                        value={emailID}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        // onChange={(e) => setEmailID(e.target.value)}

                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        // onChange={emailValidate}
                        onChange={(e) => {
                          setEmailID(e.target.value);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        Partnerships
                      </label>
                      <input
                        type="number"
                        name="partnerShips"
                        required
                        value={partnerships}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Partnerships"
                        onChange={(e) => setPartnerships(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        Accelerated
                      </label>
                      <input
                        type="number"
                        name="accelerated"
                        required
                        value={accelerated}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Accelerated"
                        onChange={(e) => setAccelerated(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        Investments
                      </label>
                      <input
                        type="number"
                        name="investments"
                        required
                        value={investments}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Investment"
                        onChange={(e) => setInvestments(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        Investment Marathon Version
                      </label>
                      <input
                        type="text"
                        name="investmentMarathonVersion"
                        required
                        value={investmentMarathonVersion}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Investment Marathon Version"
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          setInvestmentMarathonVersion(e.target.value)
                        }
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        Investment Marathon Year
                      </label>
                      <input
                        type="text"
                        name="investmentMarathonYear"
                        required
                        maxLength={2}
                        minLength={2}
                        value={investmentMarathonYear}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Investment Marathon Year"
                        onChange={(e) =>
                          setInvestmentMarathonYear(e.target.value)
                        }
                        onKeyPress={handleKeyPressForNumbers}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        Equity
                      </label>
                      <input
                        type="number"
                        name="equity"
                        required
                        value={equityData}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Equity"
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setEquityData(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-phone"
                      >
                        Contact Number
                      </label>
                      <input
                        type="text"
                        required
                        name="phone"
                        value={phone}
                        maxLength={10}
                        minLength={10}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Phone"
                        onKeyPress={handleKeyPressForNumbers}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        One Time Payment
                      </label>
                      <input
                        type="number"
                        name="oneTimePayment"
                        required
                        value={oneTimePaymentData}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="One Time Payment"
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setOneTimePaymentData(e.target.value)}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default AddConfiguartion;
