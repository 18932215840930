import axios from "axios";

/**
 *this api use for to show parner-content
 */

const { APIURL } = require("global");

const getEnquiryList = async (data) => {
  try {
    const URL = APIURL + `EnquiryByRoleId`;
    return await axios.post(`${URL}`, data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const postFeedback = async (data) => {
  try {
    const URL = APIURL + "AddFeedback";
    return await axios.post(`${URL}`, data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
  } catch (error) {
    return error.response;
  }
};

const getMenu = async (data) => {
  try {
    const URL = APIURL + "menuList";
    return await axios.post(`${URL}`, data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
  } catch (error) {
    return error.response;
  }
};

export { getEnquiryList, postFeedback, getMenu };
