import axios from "axios";
const { APIURL } = require("global");

// const getBlogList = async () => {
//   try {
//     const URL = APIURL + "blog/list";
//     return await axios.get(`${URL}`);
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };

// const getBlogContentByID = async (id) => {
//   try {
//     const URL = APIURL + `blog/${id}`;
//     return await axios.get(`${URL}`);
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };

// const getblogcategory = async (list) => {
//   try {
//     const URL = APIURL + `blogCategory/list`;
//     return await axios.get(`${URL}`);
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };

const getActiveListPortfolio = async (list) => {
  try {
    const URL = APIURL + `portfolio/activeList`;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

// const getBlogCategoryId = async (id) => {
//   try {
//     const URL = APIURL + `blogCategory/` + id;
//     return await axios.get(`${URL}`);
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };

// const createBlogCategory = async (data) => {
//   try {
//     const URL = APIURL + "blogCategory/create";
//     return await axios.post(`${URL}`, data);
//   } catch (error) {
//     return error.response;
//   }
// };

// const updateBlogCategory = async (data) => {
//   try {
//     const URL = APIURL + "blogCategory/update";
//     return await axios.post(`${URL}`, data);
//   } catch (error) {
//     return error.response;
//   }
// };

// const deleteBlogCategory = async (id) => {
//   try {
//     const URL = APIURL + "blogCategory/delete";
//     return await axios.post(`${URL}`, {
//       id: id,
//     });
//   } catch (error) {
//     return error.response;
//   }
// };

// const activateBlogCategory = async (id) => {
//   try {
//     const URL = APIURL + "blogCategory/activate";
//     return await axios.post(`${URL}`, {
//       id: id,
//     });
//   } catch (error) {
//     return error.response;
//   }
// };

// const deactivateBlogCategory = async (id) => {
//   try {
//     const URL = APIURL + "blogCategory/deactivate";
//     return await axios.post(`${URL}`, {
//       id: id,
//     });
//   } catch (error) {
//     return error.response;
//   }
// };

const getPortfoliodata = async () => {
  try {
    const URL = APIURL + `portfolio/list`;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const getportfolioId = async (id) => {
  try {
    const URL = APIURL + `portfolio/` + id;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const createportfolio = async (data) => {
  try {
    const URL = APIURL + "portfolio/create";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const updateportfolio = async (data) => {
  try {
    const URL = APIURL + "portfolio/update";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const deleteportfolio = async (portfolioID) => {
  try {
    const URL = APIURL + "portfolio/delete";
    return await axios.post(`${URL}`, {
      portfolioID: portfolioID,
    });
  } catch (error) {
    return error.response;
  }
};

const activatePortfolio = async (id) => {
  try {
    const URL = APIURL + "portfolio/activate";
    return await axios.post(`${URL}`, {
      portfolioID: id,
    });
  } catch (error) {
    return error.response;
  }
};

const deactivatePortfolio = async (id) => {
  try {
    const URL = APIURL + "portfolio/deactivate";
    return await axios.post(`${URL}`, {
      portfolioID: id,
    });
  } catch (error) {
    return error.response;
  }
};

export {
  // getBlogList,
  // getBlogContentByID,
  // getBlogCategoryId,
  // getblogcategory,
  // createBlogCategory,
  // updateBlogCategory,
  // deleteBlogCategory,
  // activateBlogCategory,
  // deactivateBlogCategory,
  // getBlogId,
  // getBlogs,
  // createBlog,
  // updateBlog,
  // deleteBlog,
  // activateBlog,
  // deactivateBlog,
  // getActiveBlogCategory,
  ////
  updateportfolio,
  createportfolio,
  getPortfoliodata,
  deleteportfolio,
  getActiveListPortfolio,
  deactivatePortfolio,
  getportfolioId,
  activatePortfolio
  

};
