import axios from "axios";
/**
 *this api use for to show testimonialList
 */
const { APIURL } = require("global");
// eslint-disable-next-line no-undef


const getActiveTestimonialList = async () => {
  const listApi = APIURL + "testimonial/activeList";
  return await fetch(listApi);
};

const GetTestmonialList = async (list) => {
  try {
    const URL = APIURL + `testimonial/list`;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
const GetTestimonialListByID = async (id) => {

  try {
    const URL = APIURL + `testimonial/` + id;
    return await axios.get(`${URL}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const CreateTestimonial = async (data) => {
  try {
    const URL = APIURL + "testimonial/create";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};
const UpdateTestimonial = async (data) => {
  try {
    const URL = APIURL + "testimonial/update";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};
const ActivateTestimonial = async (id)=> {
 
  try {
    const URL = APIURL + "testimonial/activate";
    return await axios.post(`${URL}`,  {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};
const DeactivateTestimonial = async (id) => {

  try {
    const URL = APIURL + "testimonial/deactivate";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};
const DeleteTestimonial = async (id) => {

  try {
    const URL = APIURL + "testimonial/delete";
    return await axios.post(`${URL}`, {
      id: id,
    });
  } catch (error) {
    return error.response;
  }
};

const ActiveTestimonial = async (id)=> {
 
  try {
    const URL = APIURL + "testimonial/activeList";
    return await axios.get(`${URL}`);
  } catch (error) {
    return error.response;
  }
};

export {
  getActiveTestimonialList,
  GetTestimonialListByID,
  GetTestmonialList,
  CreateTestimonial,
  UpdateTestimonial,
  ActivateTestimonial,
  DeactivateTestimonial,
  DeleteTestimonial,
  ActiveTestimonial,
};