import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Pagination from "components/admin/Admin-Pagination/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getEnquiryList } from "services/enquiry.service";

const Enquiry = () => {
  const [enquiryData, setEnquiryData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  let role = {
    id: JSON.parse(localStorage.getItem("user")).RoleId,
  };

  useEffect(() => {
    getEnquiryList(role)
      .then((response) => {
        setEnquiryData(response.data.Result);
        console.log("enquirydata", response.data.Result);
      })
      .catch((error) => {
        console.error("Error fetching enquiry data:", error);
      });
  }, []);

  return (
    <>
      <div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
        <div className="mb-0 px-4 py-3 border-0 bg-slate-300">
          <div className="flex flex-wrap items-center">
            <div className="w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-black">Enquiry</h3>
            </div>
          </div>
        </div>

        <div className=" overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr className="bg-blue-950">
                {" "}
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Sr.No
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Name
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Mobile
                </th>{" "}
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Email
                </th>{" "}
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Subject
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Desription
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Feedback
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Status
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {paginationData?.map((datalist, index) => {
                return (
                  <tr key={index}>
                    {" "}
                    <th className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {index + 1}
                    </th>
                    <th className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {datalist.Name}
                    </th>
                    <th className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {datalist.Phone}
                    </th>
                    <th className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {datalist.Email}
                    </th>
                    <th className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {datalist.Subject}
                    </th>
                    <th className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {datalist.Description}
                    </th>
                    <th className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {datalist.Feedback}
                    </th>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {datalist.Status === "Active" ? "Active" : "Resolved"}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4 space-x-4">
                      {datalist.Status === "Active" && (
                        <>
                          <Link to={"/admin/addfeedback/" + datalist.id}>
                            <i class="fas fa-edit"></i>
                          </Link>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            UserData={{
              enquiryData,
              setEnquiryData,
              paginationData,
              setPaginationData,
            }}
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Enquiry;
