import axios from "axios";
const { APIURL } = require("global");

const subscribeNewsletter = async (email) => {
  try {
    const URL = APIURL + "newsletter/create";
    return await axios.post(`${URL}`, {
      email: email,
    });
  } catch (error) {
    return error.response;
  }
};

const getSubscribeNewsLetter = async () => {
  try {
    const URL = APIURL + "newsletter/list";
    return await axios.get(`${URL}`);
  } catch (error) {
    return error.response;
  }
};

const activateNewsLetter = async (id) => {
  try {
    const URL = APIURL + "newsletter/activate";
    return await axios.post(`${URL}`, { id: id });
  } catch (error) {
    return error.response;
  }
};

const deactivateNewsLetter = async (id) => {
  try {
    const URL = APIURL + "newsletter/deactivate";
    return await axios.post(`${URL}`, { id: id });
  } catch (error) {
    return error.response;
  }
};

const deleteNewsLetter = async (id) => {
  try {
    const URL = APIURL + "newsletter/delete";
    return await axios.post(`${URL}`, { id: id });
  } catch (error) {
    return error.response;
  }
};

export {
  subscribeNewsletter,
  getSubscribeNewsLetter,
  activateNewsLetter,
  deactivateNewsLetter,
  deleteNewsLetter,
};
