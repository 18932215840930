import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ UserData }) => {
  const items =
    UserData.enquiryData ||
    UserData.imageData ||
    UserData.Users ||
    UserData.applicationData ||
    UserData.blogcategorydata ||
    UserData.timelineData ||
    UserData.portfolioData ||
    UserData.testimonialList ||
    UserData.blogdata ||
    UserData.partnerdata ||
    UserData.subscriptionData ||
    UserData.newsletter ||
    []; // Ensure UserData is an array or assign an empty array

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  useEffect(() => {
    UserData.setPaginationData(currentItems);
  }, [itemOffset, items]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        containerClassName="pagination"
        activeClassName="active"
        className=" mt-5 text-[#fff] py-[5px] flex w-full bg-[#55ad4a] [&_.previous]:border-2 [&_.previous]:rounded-[100%] [&_.previous]:px-[7px] &_.previous]:mx-[20px] [&_.next]:border-2 [&_.next]:rounded-[100%] [&_.next]:px-[7px] [&_.next]:mx-[20px] justify-center items-center [&_li]:mx-[10px]"
      />
    </>
  );
};

export default Pagination;
