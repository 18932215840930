import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Listing from "./Listing/Listing";
import RelatedPost from "./RelatedPost/RelatedPost";
import "./Funding.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

function Funding() {
  return (
    <>
      <section className="pg-funding">
        {/* Banner */}
        <section className="ev-Banner_sec">
          <div className="d-flex flex-column justify-content-center">
            <Row>
              <Col lg={12}>
                <div className="coverup">
                  <h1>Blogs</h1>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="funding-content">
          <Container>
            <div></div>
            <Row>
              <Col lg={3}>
                <div className="fund-bg-search">
                  <label>
                    <input type="text" placeholder="Search" />
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="search-icon"
                    />
                  </label>
                </div>
                <RelatedPost />
              </Col>
              <Col lg={9}>
                <div className="fund-bg-head">
                  <h5>Blogs</h5>
                </div>
                <Listing />
              </Col>
            </Row>
          </Container>
        </section>
      </section>
    </>
  );
}

export default Funding;
