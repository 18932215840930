import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postFeedback } from "services/enquiry.service";

export const EditImage = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const param = useParams();
  const navigate = useNavigate();
  const [createBy, setCreateBy] = useState(user.id);
  const [formData, setFormData] = useState({
    id: param.id,
    feedback: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const addFeedback = (e) => {
    e.preventDefault();
    postFeedback(formData).then((response) => {
      if (response.data.Status === "Success" && !response.data.Error) {
        const notify = () => {
          toast.success(response.data.Message);
        };
        setTimeout(() => {
          navigate("/admin/enquirylist");
        }, 2000);
        notify();
      } else {
        const notify = () => {
          toast.error(Object.values(response.data.errors)[0][0]);
        };
        notify();
      }
    });
  };

  const handleNavigate = () => {
    navigate("/admin/enquirylist");
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          {" "}
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add Feedback</h3>
            </div>

            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                {" "}
                <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center"></div>
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={addFeedback}>
                    <input type="hidden" name="createby" value={createBy} />
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase my-2 text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        FeedBack <span className="text-red-500">*</span>
                      </label>
                      <textarea
                        type="text"
                        required
                        name="feedback"
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                        }`}
                        placeholder="FeedBack"
                        value={formData.feedback}
                        onChange={handleChange}
                      ></textarea>
                      <div className="flex">
                        <div className="text-left mt-6 mr-4">
                          <button
                            className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mb-1 ease-linear transition-all duration-150"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                        <div className="text-left mt-6">
                          <button
                            className="bg-blue-600 active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={handleNavigate}
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default EditImage;
