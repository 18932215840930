import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ForgotPass.css";
import { userForgetEmail } from "services/user.service";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const forgotInitialValues = {
  email: "",
};
function ForgotPass() {
  const [email, setEmail] = useState(forgotInitialValues);
  const [error, showError] = useState(false);
  const navigate = useNavigate();

  const onInputChange = (e) => {
    setEmail({ ...email, [e.target.name]: e.target.value });
    console.log(email);
  };

  const forgotEmail = async () => {
    let response = await userForgetEmail(email);
    if (response.data.error === true) {
      showError(true);
    } else {
      showError(false);
      localStorage.setItem("userID", `${response.data.result.userid}`);
      toast.success(response.data.message)
      setTimeout(()=>{
        navigate("/resetPass");
      },2000);
    }
  };

  return (
    <>
      <section className="pg-forgot-pass">
        <div className="form sign-in">
          <h2 style={{ color: "green" }}>Forgot Password</h2>
          <label>
            {error && (
              <div style={{ color: "red" }}>
                Invalid Email / Email not Registered
              </div>
            )}
            <span>Enter your Email</span>
            <input
              type="text"
              onChange={(e) => onInputChange(e)}
              name="email"
            />
          </label>

          <button
            type="button"
            className="forgot-sendbtn"
            onClick={() => forgotEmail()}
          >
            <p> Send </p>
          </button>

          <div className="forgotPass-or">
            <div className="or-block">
              <p>OR</p>
              <hr />
            </div>
            <p>Don't have an account?</p>
            <Link to="/login">
              <button className="signup-btn">Sign Up</button>
            </Link>
          </div>
        </div>
      </section>
      <ToastContainer/>
    </>
  );
}

export default ForgotPass;
