import axios from "axios";
const { APIURL } = require("global");

const getBannerImage = async () => {
  try {
    const URL = APIURL + "BannerImage";
    return await axios.get(`${URL}`);
  } catch (error) {
    return error.response;
  }
};

const getComponent = async () => {
  try {
    const URL = APIURL + "ComponentList";
    return await axios.get(`${URL}`, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
  } catch (error) {
    return error.response;
  }
};

const activeImage = async (data) => {
  try {
    const URL = APIURL + "ActiveImageById";
    return await axios.post(`${URL}`, data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
  } catch (error) {
    return error.response;
  }
};

const inActiveImage = async (data) => {
  try {
    const URL = APIURL + "InactiveImageById";
    return await axios.post(`${URL}`, data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
  } catch (error) {
    return error.response;
  }
};
const getImageByComponentId = async (data) => {
  try {
    const URL = APIURL + "BannerImageById";
    return await axios.post(`${URL}`, data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
  } catch (error) {
    return error.response;
  }
};

const addImage = async (data) => {
  try {
    const URL = APIURL + "addImage";
    return await axios.post(`${URL}`, data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
  } catch (error) {
    return error.response;
  }
};
export {
  getBannerImage,
  getComponent,
  getImageByComponentId,
  activeImage,
  inActiveImage,
  addImage,
};
