import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  handleKeyPressforSpecialCharacters,
  NameRegex,
} from "components/RegexValidation";
import {
  CreateTestimonial,
  UpdateTestimonial,
  GetTestmonialList,
  GetTestimonialListByID,
  DeleteTestimonial,
} from "services/testimonials.service";
import { IMAGEURL } from "global";

const AddTestimonial = () => {
  const param = useParams();

  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [content, setContent] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [size, setSize] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [profilePicPreview, setProfilePicPreview] = useState("");
  const [createBy, setCreateBy] = useState(user.AdminID);
  const [disable, setDisable] = useState(false);

  const uploadProfilePic = (e) => {
    let file = e.target.files[0];
    let ext = file !== undefined ? file.name?.split(".")[1] : "";
    if (
      ext.toLowerCase() === "jpeg" ||
      ext.toLowerCase() === "jpg" ||
      ext.toLowerCase() === "png"
    ) {
      if (file) {
        const reader = new FileReader();
        setSize(file.size);
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      }
    } else if (ext === "") {
      return false;
    } else {
      setProfilePic("");
      toast.error("Image must be jpg, jpeg and png extension");
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let setDp = "data:image/png;base64," + btoa(binaryString);
    setProfilePic(btoa(binaryString));
    setProfilePicPreview(setDp);
  };

  useEffect(() => {
    // if (param !== null) {
    if (typeof param.id !== "undefined") {
      try {
        GetTestimonialListByID(param.id).then((response) => {
          let testimonialListDetail = response.data.Result[0];
          setId(testimonialListDetail.TestimonialID);
          // console.log(id)
          // setTestimonialListID(testimonialListDetail.testimonialListID);
          setName(testimonialListDetail.Name);
          setPosition(testimonialListDetail.Position);
          setContent(testimonialListDetail.Content);
          setProfilePicPreview(testimonialListDetail.ProfilePic);
        });
      } catch (error) {
        console.log(error);
      }
    }
    GetTestmonialList().then((response) => {
      setTestimonial(response.data.Result);
    });
  }, []);

  const addTestimonial = async (e) => {
    e.preventDefault();

    if (name.trim() === "" || position.trim() === "") {
      // Handle the case when the input field is blank
      console.log("Input field cannot be blank");
      toast.warn("Input field cannot be blank");
      return;
    }

    const testimonial = {
      id: e.target.id.value,
      name: name.replace("'", "''"),
      position: position.replace("'", "''"),
      content: content,
      profilePic: param.id ? (profilePic !== "" ? profilePic : "") : profilePic,
      createby: JSON.parse(localStorage.getItem("user")).AdminID,
    };

    let response;

    if (e.target.id.value === "") {
      response = await CreateTestimonial(testimonial);
    } else {
      response = await UpdateTestimonial(testimonial);
    }

    if (response.status === 200 && !response.data.error) {
      toast.success(response.data.Message);
      setDisable(true);
      setTimeout(() => {
        navigate("/admin/testimoniallist");
      }, 2000);
    } else {
      toast.error(response.data.Message);
    }
  };
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData("text/plain");
    const pattern = /^[a-zA-Z]*$/; // Regular expression pattern to allow alphanumeric characters
    if (pattern.test(pastedValue)) {
      setName(pastedValue);
    }
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                {param.id ? "Edit" : "Add"} Testimonial
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={addTestimonial}>
                    <input type="hidden" name="createby" value={createBy} />
                    <input
                      type="hidden"
                      name="id"
                      value={param.id ? param.id : ""}
                    />
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        value={name}
                        type="text"
                        autoFocus
                        maxLength={20}
                        name="Name"
                        // required
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Name"
                        onKeyPress={handleKeyPressforSpecialCharacters}
                        onPaste={handlePaste}
                        // onChange={(e) => setName(e.target.value)}
                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.preventDefault();
                          } else {
                            setName(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Position <span className="text-red-500">*</span>
                      </label>
                      <input
                        value={position}
                        type="text"
                        name="Position"
                        maxLength={20}
                        // required
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                      }`}
                        placeholder="Position"
                        // onKeyPress={handleKeyPress}

                        // onChange={(e) => setPosition(e.target.value)}

                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.preventDefault();
                          } else {
                            setPosition(e.target.value);
                          }
                        }}
                      />
                      <label
                        className="block uppercase my-3 text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Content <span className="text-red-500">*</span>
                      </label>

                      <input
                        value={content}
                        type="text"
                        name="Content"
                        required
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                      }`}
                        placeholder="Content"
                        // onChange={(e) => setContent(e.target.value)}
                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.preventDefault();
                          } else {
                            setContent(e.target.value);
                          }
                        }}
                      />

                      <div className="relative w-full mb-3 py-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-Name"
                        >
                          Profile Pic{" "}
                          {param.id ? (
                            ""
                          ) : (
                            <span className="text-red-500">*</span>
                          )}
                        </label>

                        <input
                          type="file"
                          name="profilePic"
                          required={param.id ? false : true}
                          className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                      }`}
                          accept=".jpeg,.jpg,.png"
                          onChange={uploadProfilePic}
                        />
                        {profilePic === "" ? (
                          <img
                            src={`${IMAGEURL}${profilePicPreview}`}
                            width="100"
                            alt=""
                          />
                        ) : (
                          <img
                            src={`${profilePicPreview}`}
                            width="100"
                            alt=""
                          />
                        )}
                      </div>

                      <div className="text-left mt-6">
                        <button
                          className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit"
                          disabled={disable}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default AddTestimonial;
