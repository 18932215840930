import axios from "axios";
const { APIURL } = require("global");

const sendMailForContactUs = async (data) => {
  try {
    // const URL = APIURL + "AddEnquiry";
    const URL = "http://localhost:3009/api/v1/createEnquiry";
    return await axios.post(`${URL}`, data);
  } catch (error) {
    return error.response;
  }
};

const getRole = async () => {
  try {
    // const URL = APIURL + "RoleList";
    const URL = "http://localhost:3009/api/v1/RoleList";
    return await axios.get(`${URL}`);
  } catch (error) {
    return error.response;
  }
};

export { getRole, sendMailForContactUs };
