import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getComponent } from "services/image.service";
import { IMAGEURL } from "global";
import { addImage } from "services/image.service";

export const AddImage = () => {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState("");
  const [component, setComponent] = useState([]);
  const [base64, setBase64] = useState("");
  const [size, setSize] = useState("");
  const [formData, setFormData] = useState({
    componentId: null,
    image: "",
    createBy: JSON.parse(localStorage.getItem("user")).id,
  });

  useEffect(() => {
    getComponent()
      .then((response) => {
        setComponent(response.data.Result);
      })
      .catch((error) => {
        console.error("Error fetching enquiry data:", error);
      });
  }, []);

  const uploadImage = (e) => {
    let file = e.target.files[0];
    let ext = file !== undefined ? file.name?.split(".")[1] : "";

    if (
      ext.toLowerCase() === "jpeg" ||
      ext.toLowerCase() === "jpg" ||
      ext.toLowerCase() === "png"
    ) {
      if (file) {
        const reader = new FileReader();
        setSize(file.size);
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
        console.log("size,", size);
      }
    } else if (ext === "") {
      return false;
    } else {
      setBase64("");
      setImagePreview("");
      toast.error("Image must be jpg, jpeg and png extension");
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let encodedBase64 = btoa(binaryString);
    setBase64(encodedBase64);
    setImagePreview("data:image/png;base64," + encodedBase64);
    setFormData({
      ...formData,
      image: encodedBase64,
    });
  };
  const handleChange = async (e) => {
    const selectedComponentId = e.target.value;
    setFormData({
      ...formData,
      componentId: selectedComponentId,
    });
  };

  const imageAdd = (e) => {
    e.preventDefault();
    addImage(formData).then((response) => {
      if (response.data.Status === "Success" && !response.data.Error) {
        const notify = () => {
          toast.success(response.data.Message);
        };
        setTimeout(() => {
          // Reset form data and state variables
          setFormData({
            componentId: null,
            image: "",
          });
          setBase64("");
          setImagePreview("");
          fileInputRef.current.value = "";
        }, 1000);

        notify();
      } else {
        const notify = () => {
          toast.error(Object.values(response.data.errors)[0][0]);
        };
        notify();
      }
    });
  };
  const handleNavigate = () => {
    navigate("/admin/imagelist");
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add New Image</h3>
            </div>

            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={imageAdd}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Select Component
                      </label>
                      <select
                        name="componentId"
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        value={
                          formData.componentId === null
                            ? "Select Component"
                            : formData.componentId
                        }
                        onChange={handleChange}
                      >
                        <option>Select Component</option>
                        {component.map((component) => (
                          <option key={component.id} value={component.id}>
                            {component.Name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Upload Image
                      </label>
                      <input
                        type="file"
                        name="image"
                        ref={fileInputRef}
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                        }`}
                        accept=".jpeg,.jpg,.png"
                        onChange={uploadImage}
                      />
                      {base64 === "" ? (
                        <img
                          src={`${IMAGEURL}${imagePreview}`}
                          width="100"
                          alt=""
                        />
                      ) : (
                        <img src={`${imagePreview}`} width="100" alt="" />
                      )}
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                    <div className="text-left mt-6">
                      <button
                        className="bg-blue-600 active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={handleNavigate}
                      >
                        Back
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default AddImage;
