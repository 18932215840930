import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {applicationReview,applicationRead,applicationAccept,applicationReject,} from "services/application.service";

export const ApplicationRead = () => {
  const [applicationDatas, setApplicationData] = useState([]);
  const [applicationMemberFormData, setApplicationMemberFormData] = useState([]);
  const param = useParams();
  const navigate = useNavigate();

  var totalRevenueEarned = useRef();
  var totalExpensesIncurred = useRef();
  var salesAndMarketing = useRef();
  var uniqueUserFree = useRef();
  var uniqueUserPaid = useRef();
  var uniqueCustomersFree = useRef();
  var uniqueCustomersPaid = useRef();
  var transactions = useRef();
  
  useEffect(() => {
    applicationRead(param.id).then((response) => {
      setApplicationData(response.data.Result[0].applicationFormData);
      setApplicationMemberFormData(response.data.Result[0].applicationMember);
      totalRevenueEarned.current =
        response.data.Result[0].applicationFormData[0].TotalRevenueEarned.split(
          "~"
        );
      totalExpensesIncurred.current =
        response.data.Result[0]?.applicationFormData[0]?.TotalExpensesIncurred?.split(
          "~"
        );
      salesAndMarketing.current =
        response.data.Result[0]?.applicationFormData[0]?.SalesAndMarketing?.split(
          "~"
        );
      uniqueUserFree.current =
        response.data.Result[0]?.applicationFormData[0]?.UniqueUserFree?.split(
          "~"
        );
      uniqueUserPaid.current =
        response.data.Result[0]?.applicationFormData[0]?.UniqueUserPaid?.split(
          "~"
        );
      uniqueCustomersFree.current =
        response.data.Result[0]?.applicationFormData[0]?.UniqueCustomersFree?.split(
          "~"
        );
      uniqueCustomersPaid.current =
        response.data.Result[0]?.applicationFormData[0]?.UniqueCustomersPaid?.split(
          "~"
        );
      transactions.current =
        response.data.Result[0]?.applicationFormData[0]?.Transactions?.split(
          "~"
        );
    });
  }, []);

  const handleReview = (e, ApplicationData) => {
    const obj = {
      id: ApplicationData[0].ApplicationID,
      userID: ApplicationData[0].UserID,
    };
    applicationReview(obj).then((response) => {
      if (
        response.data.Status === "Success" &&
        response.data.ErrorCode === "200"
      ) {
        navigate("/admin/application");
      }
    });
  };

  const handleAccept = (e, ApplicationData) => {
    const obj = {
      id: ApplicationData[0].ApplicationID,
      userID: ApplicationData[0].UserID,
    };
    applicationAccept(obj).then((response) => {
      if (
        response.data.Status === "Success" &&
        response.data.ErrorCode === "200"
      ) {
        navigate("/admin/application");
      }
    });
  };

  const handleReject = (e, ApplicationData) => {
    const obj = {
      id: ApplicationData[0].ApplicationID,
      userID: ApplicationData[0].UserID,
    };
    applicationReject(obj).then((response) => {
      if (
        response.data.Status === "Success" &&
        response.data.ErrorCode === "200"
      ) {
        navigate("/admin/application");
      }
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6 [&_button]:px-4 [&_button]:py-1 [&_button]:border-2 [&_button]:bg-[green] [&_button]:rounded-[6px] [&_button]:text-[#fff] [&_button]:m-2">
                  {" "}
                  <table className="w-full table-auto [&_tr]:border-y-2 [&_td]:p-4 [&_td:nth-child(1)]:w-[30%] [&_td:nth-child(2)]:w-[70%]  [&_td:nth-child(1)]:text-[#747474] [&_td:nth-child(1)]:bg-[#a9a5a524]">
                    {applicationDatas?.length > 0
                      ? applicationDatas?.map((item) => {
                          return (
                            <tbody>
                              <tr>
                                <td>BrandName: </td>
                                <td>{item?.BrandName}</td>
                              </tr>
                              <tr>
                                <td>Website: </td>
                                <td>{item?.Website}</td>
                              </tr>
                              <tr>
                                <td>VentureStage: </td>
                                <td>{item?.VentureStage}</td>
                              </tr>
                              <tr>
                                <td>FundingStage: </td>
                                <td> {item?.FundingStage}</td>
                              </tr>
                              <tr>
                                <td>Purpose: </td>
                                <td>{item?.Purpose}</td>
                              </tr>
                              <tr>
                                <td>IncorporationDate: </td>
                                <td>
                                  {item?.IncorporationDate.toString().slice(
                                    0,
                                    10
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>MarketDate: </td>
                                <td>
                                  {item?.MarketDate.toString().slice(0, 10)}
                                </td>
                              </tr>
                              <tr>
                                <td>Category: </td>
                                <td>{item?.Category}</td>
                              </tr>
                              <tr>
                                <td>Stage: </td>
                                <td>{item?.Stage}</td>
                              </tr>
                              <tr>
                                <td>ProblemStatement: </td>
                                <td>{item?.ProblemStatement}</td>
                              </tr>
                              <tr>
                                <td>Solution: </td>
                                <td>{item?.Solution}</td>
                              </tr>
                              <tr>
                                <td>ProductDescription: </td>
                                <td>{item?.ProductDescription}</td>
                              </tr>
                              <tr>
                                <td>UserJourney:</td>
                                <td>{item?.UserJourney}</td>
                              </tr>
                              <tr>
                                <td>Validation: </td>
                                <td>{item?.Validation}</td>
                              </tr>
                              <tr>
                                <td>TargetGroup:</td>
                                <td>{item?.TargetGroup}</td>
                              </tr>
                              <tr>
                                <td>AnnualUser: </td>
                                <td>{item?.AnnualUser}</td>
                              </tr>
                              <tr>
                                <td>AnnualRevenue: </td>
                                <td>{item?.AnnualRevenue}</td>
                              </tr>
                              <tr>
                                <td>Max_ARPU: </td>
                                <td>{item?.Max_ARPU}</td>
                              </tr>
                              <tr>
                                <td>Assumption: </td>
                                <td>{item?.Assumption}</td>
                              </tr>
                              <tr>
                                <td>Competitive: </td>
                                <td>{item?.Competitive}</td>
                              </tr>
                              <tr>
                                <td>Differentiation: </td>
                                <td> {item?.Differentiation}</td>
                              </tr>
                              <tr>
                                <td>LongTermCompetitiveAdvantage: </td>
                                <td>{item?.LongTermCompetitiveAdvantage}</td>
                              </tr>
                              <tr>
                                <td>RevenueModel:</td>
                                <td>{item?.RevenueModel}</td>
                              </tr>
                              <tr>
                                <td>ProductionMethod: </td>
                                <td>{item?.ProductionMethod}</td>
                              </tr>
                              <tr>
                                <td>GrowthMethod: </td>
                                <td>{item?.GrowthMethod}</td>
                              </tr>
                              <tr>
                                <td>ServiceMethod:</td>
                                <td>{item?.ServiceMethod}</td>
                              </tr>
                              <tr>
                                <td>RenewalMethod:</td>
                                <td>{item?.RenewalMethod}</td>
                              </tr>
                              <tr>
                                <td>RightToExist:</td>
                                <td>{item?.RightToExist}</td>
                              </tr>
                              <tr>
                                <td>FundingAsk:</td>
                                <td>{item?.FundingAsk}</td>
                              </tr>
                              <tr>
                                <td>FundingExpected:</td>
                                <td>{item?.FundingExpected}</td>
                              </tr>
                              <tr>
                                <td>ShareholdingFounder: </td>
                                <td>{item?.ShareholdingFounder}</td>
                              </tr>
                              <tr>
                                <td>ShareholdingOther: </td>
                                <td>{item?.ShareholdingOther}</td>
                              </tr>
                            </tbody>
                          );
                        })
                      : ""}
                  </table>
                  <div className="mt-4">
                    <label>Core Team Details</label>
                    <br />
                    <table className="w-full m-2 [&_tr]:border-y-2 [&_td]:py-4 [&_td]:text-sm [&_th]:w-[10%] text-center [&_th]:bg-[#a9a5a524] [&_th]:text-[#8f8e8e]">
                      <thead>
                        <th>FullName</th>
                        <th>Age</th>
                        <th>Latest Qualification</th>
                        <th>Latest Institute Attended</th>
                        <th>Total Work Experience</th>
                        <th>Role</th>
                        <th>Member Status</th>
                      </thead>
                      {applicationMemberFormData?.length > 0
                        ? applicationMemberFormData?.map((item) => (
                            <tbody>
                              <tr>
                                <td>{item.FullName}</td>
                                <td>{item.Age}</td>
                                <td>{item.LatestQualification}</td>
                                <td>{item.LatestInstituteAttended}</td>
                                <td>{item.TotalWorkExperience}</td>
                                <td>{item.Role}</td>
                                <td>{item.MemberStatus}</td>
                              </tr>
                            </tbody>
                          ))
                        : "No Data Found"}
                    </table>
                    <div>
                      <label>Execution:</label>

                      <div>
                        <table>
                          <tr>
                            <th></th>
                            <th>aggregate </th>
                            <th>Last Year </th>
                            <th>Last Month </th>
                            <th>Likely Happen </th>
                          </tr>
                          <tr>
                            <td>Total Revenue Earned</td>
                            {totalRevenueEarned.current?.length > 0
                              ? totalRevenueEarned.current.map((item) => (
                                  <td>{item !== "null" ? item : ""}</td>
                                ))
                              : "No Data Found"}
                          </tr>
                          <tr>
                            <td>Total Expenses Incurred</td>
                            {totalExpensesIncurred.current?.length > 0
                              ? totalExpensesIncurred.current.map((item) => (
                                  <td>{item !== "null" ? item : ""}</td>
                                ))
                              : "No Data Found"}
                          </tr>
                          <tr>
                            <td>Sales and Marketing</td>
                            {salesAndMarketing.current?.length > 0
                              ? salesAndMarketing.current.map((item) => (
                                  <td>{item !== "null" ? item : ""}</td>
                                ))
                              : ""}
                          </tr>
                          <tr>
                            <td>Unique User Free</td>
                            {uniqueUserFree.current?.length > 0
                              ? uniqueUserFree.current.map((item) => (
                                  <td>{item !== "null" ? item : ""}</td>
                                ))
                              : "No Data Found"}
                          </tr>
                          <tr>
                            <td>Unique User Paid</td>
                            {uniqueUserPaid.current?.length > 0
                              ? uniqueUserPaid.current.map((item) => (
                                  <td>{item !== "null" ? item : ""}</td>
                                ))
                              : "No Data Found"}
                          </tr>
                          <tr>
                            <td>Unique Customers Free</td>
                            {uniqueCustomersFree.current?.length > 0
                              ? uniqueCustomersFree.current.map((item) => (
                                  <td>{item !== "null" ? item : ""}</td>
                                ))
                              : "No Data Found"}
                          </tr>
                          <tr>
                            <td>Unique Customers Paid</td>
                            {uniqueCustomersPaid.current?.length > 0
                              ? uniqueCustomersPaid.current.map((item) => (
                                  <td>{item !== "null" ? item : ""}</td>
                                ))
                              : "No Data Found"}
                          </tr>
                          <tr>
                            <td>Transactions</td>
                            {transactions.current?.length > 0
                              ? transactions.current.map((item) => (
                                  <td>{item !== "null" ? item : ""}</td>
                                ))
                              : "No Data Found"}
                          </tr>
                        </table>
                      </div>
                      <br />
                    </div>
                  </div>
                  <button
                    onClick={(e) => {
                      handleReview(e, applicationDatas);
                    }}
                  >
                    Review
                  </button>
                  <button
                    onClick={(e) => {
                      handleAccept(e, applicationDatas);
                    }}
                  >
                    Accept
                  </button>
                  <button
                    onClick={(e) => {
                      handleReject(e, applicationDatas);
                    }}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ApplicationRead;
