import React, { useState } from "react";
import { Col, Container, Row, Tab, Table, Tabs } from "react-bootstrap";
import "./Dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import BusinessPlan from "./Dashboard-Tabs/BusinessPlan";
import DetailedInfo from "./Dashboard-Tabs/DetailedInfo";
import EvaluationReport from "./Dashboard-Tabs/EvaluationReport";
import DashboardProfile from "./Dashboard-Profile/DashboardProfile";
import { Link } from "react-router-dom";
import { getApplicationListForDashboard } from "services/application.service";
import { useEffect } from "react";

function Dashboard() {
  const [applications, setApplications] = useState([]);

  const user = JSON.parse(localStorage.getItem("loggedUser"));
  const dateString = "2023-05-16T16:51:46.027Z";
  const date = new Date(dateString);

  function dateForm(item) {
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    return formattedDate;
  }
  useEffect(() => {
    getApplicationListForDashboard(user.userId).then((response) => {
      setApplications(response.data.Result);
    });
  }, []);

  return (
    <>
      <section className="board">
        <div className="flexi">
          <div className="container">
            <div className="flexi">
              <Row>
                <Col lg={12}>
                  <h2 className="dashboard-head">
                    <span>Trisha</span>'s Test Profile
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <DashboardProfile />
                </Col>
                {/* My Account Dashboard */}
                <Col lg={12}>
                  <div className="dashboard-account">
                    <Container>
                      <div className="flex flex-row justify-between mb-[40px]">
                        <h3 className="text-lg">My Applications</h3>
                        <Link to="/dashboardForm" className="submit-app-btn">
                          Submit Application
                        </Link>
                      </div>

                      <div>
                        <table>
                          <thead>
                            <th>Name</th>
                            <th>Date of Submission</th>
                            <th>Status</th>
                          </thead>
                          <tbody>
                            {applications.length > 0
                              ? applications.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.BrandName}</td>
                                    <td>{dateForm(item.CreateDate)}</td>
                                    <td>{item.CStatus}</td>
                                  </tr>
                                ))
                              : ""}
                            {/* <tr>
                              <td>Application 1</td>
                              <td>12/05/2023</td>
                              <td>Not Submit</td>
                            </tr> */}
                            {}
                          </tbody>
                        </table>
                      </div>
                    </Container>
                  </div>
                </Col>
                {/* Dashboard Form */}
                <Col lg={12}></Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard;
