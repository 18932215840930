import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DeleteTimeline } from "services/timeline.service";
import {GetTimelineList, ActivateTimeline, DeactivateTimeline,} from "services/timeline.service";
import Pagination from "components/admin/Admin-Pagination/Pagination";
const { IMAGEURL } = require("global");

const ListTimeLine = () => {
  const [timelineData, setTimelineData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    GetTimelineList().then((response) => {
      setTimelineData(response.data.Result);
    });
  }, []);

  const handleChange = (data, status) => {
    if (status === "activate") {
      ActivateTimeline(data).then((Response) => {
        console.log("ActiveTimelineDta", Response);
        if (Response.data.Status === "Success") {
          GetTimelineList().then((res) => {
            setTimelineData(res.data.Result);
            const notify = () => toast.success(Response.data.Message);
            notify();
          });
        } else {
          const notify = () => toast.error(Response.data.Message);
          notify();
        }
      });
    } else if (status === "deactivate") {
      DeactivateTimeline(data).then((Response) => {
        if (Response.data.Status === "Success") {
          GetTimelineList().then((res) => {
            setTimelineData(res.data.Result);
            const notify = () => toast.success(Response.data.Message);
            notify();
          });
        } else {
          const notify = () => toast.error(Response.data.Message);
          notify();
        }
      });
    } else {
      DeleteTimeline(data).then((Response) => {
        if (Response.data.Status === "Success") {
          GetTimelineList().then((res) => {
            setTimelineData(res.data.Result);
            const notify = () => toast.success(Response.data.Message);
            notify();
          });
        } else {
          const notify = () => toast.error(Response.data.Message);
          notify();
        }
      });
    }
  };

  const handleNavigate = () => {
    navigate("/admin/addTimeline")
  }

  return (
    <>
      <div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
        <div className="mb-0 px-4 py-3 border-0 bg-slate-300">
          <div className="flex flex-wrap items-center">
            <div className="w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-black">Timeline </h3>
            </div>
            <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
              <div className=" mt-32 sm:mt-0">
                <button
                  className="bg-slate-950	active:bg-lightBlue-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleNavigate}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr className="bg-blue-950">
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Image
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Title
                </th>
                {/* <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Description
                </th> */}
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Status
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {paginationData?.map((datalist, index) => {
                return (
                  <tr key={index}>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      <img
                        src={`${IMAGEURL}${datalist.Image}`}
                        width={"50"}
                        alt=""
                      />
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {datalist.Title}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4">
                      {datalist.CStatus}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6 align-middle text-black text-xs whitespace-nowrap p-4 space-x-4">
                      {datalist.CStatus === "Active" && (
                        <>
                          <Link
                            to={"/admin/editTimeline/" + datalist.TimelineID}
                          >
                            <i class="fas fa-edit"></i>
                          </Link>
                        </>
                      )}

                      {(datalist.CStatus === "Active" ||
                        datalist.CStatus === "Deactive") && (
                        <>
                          <button
                            onClick={(e) =>
                              handleChange(datalist.TimelineID, "delete")
                            }
                          >
                            <i class="fas fa-trash text-red-600"></i>
                          </button>
                        </>
                      )}

                      {(datalist.CStatus === "Deactive" ||
                        datalist.CStatus === "Delete") && (
                        <>
                          <button
                            onClick={(e) =>
                              handleChange(datalist.TimelineID, "activate")
                            }
                          >
                            <i class="fas fa-check-circle"></i>
                          </button>
                        </>
                      )}
                      {(datalist.CStatus === "Active" ||
                        datalist.CStatus === "Delete") && (
                        <>
                          <button
                            onClick={() =>
                              handleChange(datalist.TimelineID, "deactivate")
                            }
                          >
                            <i class="fas fa-ban text-red-600"></i>
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination UserData={{timelineData, setTimelineData, paginationData, setPaginationData}} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ListTimeLine;
