import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  CreateSubscription,
  UpdateSubscription,
  GetSubscriptionByID,
  //   getActiveBlogCategory,
} from "services/subscription.service";
import { handleKeyPressforSpecialCharacters } from "components/RegexValidation";
import { data } from "autoprefixer";

export const AddSubscription = () => {
  const param = useParams();
  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [subscriptionPrice, setSubscriptionPricre] = useState("");
  const [email, setEmail] = useState("");
  const [point1, setPoint1] = useState("");
  const [point2, setPoint2] = useState("");
  const [point3, setPoint3] = useState("");
  const [point4, setPoint4] = useState("");
  const [point5, setPoint5] = useState("");
  const [point6, setPoint6] = useState("");
  const [point7, setPoint7] = useState("");
  const [point8, setPoint8] = useState("");

  const [shortDescription, setShortDescription] = useState("");
  const [subscriptionID, setSubscriptionID] = useState("");
  const [createby, setCreateby] = useState(user.AdminID);

  const [TitleError, setTitleError] = useState(false);
  const [error, setError] = useState(false);
  
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (typeof param.id !== "undefined") {
      //   GetSubscriptionByID(param.id).then((response)=> response.json()).then((data) => {
      //     console.log('data',data);
      GetSubscriptionByID(param.id).then((response) => {
        console.log("data", data);
        let subscriptionDetail = response.data.Result[0];
        setSubscriptionID(subscriptionDetail.SubscriptionID);
        setTitle(subscriptionDetail.Title);
        setSubscriptionPricre(subscriptionDetail.Price);
        setEmail(subscriptionDetail.EmailID);
        setPoint1(subscriptionDetail.Point1);
        setPoint2(subscriptionDetail.Point2);
        setPoint3(subscriptionDetail.Point3);
        setPoint4(subscriptionDetail.Point4);
        setPoint5(subscriptionDetail.Point5);
        setPoint6(subscriptionDetail.Point6);
        setPoint7(subscriptionDetail.Point7);
        setPoint8(subscriptionDetail.Point8);
        setShortDescription(subscriptionDetail.ShortDescription);
      });
    }
  }, []);

  const addSubscription = async (e) => {
    e.preventDefault();

if (title.trim() === '' || shortDescription.trim() === '') {
      // Handle the case when the input field is blank
       console.log('Input field cannot be blank');
      toast.warn("Input field cannot be blank");
      return;
    }
    const subscriptionData = {
      subscriptionID: e.target.id.value,
      title: title,
      emailID: email,
      price: subscriptionPrice,
      point1: point1,
      point2: point2,
      point3: point3,
      point4: point4,
      point5: point5,
      point6: point6,
      point7: point7,
      point8: point8,
      shortDescription: shortDescription,
      createBy: createby,
    };
    setDisable(true)

    let response;

    if (e.target.id.value === "") {
      response = await CreateSubscription(subscriptionData);
    } else {
      response = await UpdateSubscription(subscriptionData);
    }

    if (response.status === 200 && !response.data.error) {
      // const notify = () => toast.success(response.data.Message);
      // notify();
      toast.success(response.data.Message);
      setTimeout(() => {
        navigate("/admin/subscription");
      }, 2000);
    } else {
      // const notify = () => toast("Please enter the require valid field");
      // notify();
      toast.error(response.data.message);
    }

    console.log(response);
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              {/* <h3 className="mx-4 my-2 text-[24px] font-bold">Add New Blog</h3> */}

              <h3 className="mx-4 my-2 text-[24px] font-bold">
                {param.id ? "Edit" : "Add New"} Subscription
              </h3>
            </div>

            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={addSubscription}>
                    <input type="hidden" name="id" value={subscriptionID} />
                    <input type="hidden" name="createby" value={createby} />

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={title}
                        // maxLength={20}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Title"
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            setTitleError(false);
                            e.preventDefault();
                          } else {
                            setTitleError(false);
                            setTitle(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        Price
                      </label>
                      <input
                        type="number"
                        name="price"
                        required
                        value={subscriptionPrice}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Price"
                        onChange={(e) => setSubscriptionPricre(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        required
                        value={email}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        // onChange={emailValidate}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Point1
                      </label>
                      <input
                        type="text"
                        name="point1"
                        value={point1}
                        // maxLength={20}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Point1"
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            // setTitleError(false);
                            e.preventDefault();
                          } else {
                            // setTitleError(false);
                            setPoint1(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Point2
                      </label>
                      <input
                        type="text"
                        name="point2"
                        value={point2}
                        // maxLength={20}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Point2"
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            // setTitleError(false);
                            e.preventDefault();
                          } else {
                            // setTitleError(false);
                            setPoint2(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Point3
                      </label>
                      <input
                        type="text"
                        name="point3"
                        value={point3}
                        // maxLength={20}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Point3"
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            // setTitleError(false);
                            e.preventDefault();
                          } else {
                            // setTitleError(false);
                            setPoint3(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Point4
                      </label>
                      <input
                        type="text"
                        name="point4"
                        value={point4}
                        // maxLength={20}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Point4"
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            // setTitleError(false);
                            e.preventDefault();
                          } else {
                            // setTitleError(false);
                            setPoint4(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Point5
                      </label>
                      <input
                        type="text"
                        name="point5"
                        value={point5}
                        // maxLength={20}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Point5"
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            // setTitleError(false);
                            e.preventDefault();
                          } else {
                            // setTitleError(false);
                            setPoint5(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Point6
                      </label>
                      <input
                        type="text"
                        name="point6"
                        value={point6}
                        // maxLength={20}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Point6"
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            // setTitleError(false);
                            e.preventDefault();
                          } else {
                            // setTitleError(false);
                            setPoint6(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Point7
                      </label>
                      <input
                        type="text"
                        name="point7"
                        value={point7}
                        // maxLength={20}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Point7"
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            // setTitleError(false);
                            e.preventDefault();
                          } else {
                            // setTitleError(false);
                            setPoint7(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Point8
                      </label>
                      <input
                        type="text"
                        name="point8"
                        value={point8}
                        // maxLength={20}
                        className="border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150"
                        placeholder="Point8"
                        // onKeyPress={handleKeyPressforSpecialCharacters}
                        // onChange={(e) => setTitle(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            // setTitleError(false);
                            e.preventDefault();
                          } else {
                            // setTitleError(false);
                            setPoint8(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Name"
                      >
                        Short Description
                      </label>
                      <input
                        type="text"
                        name="shortDescription"
                        value={shortDescription}
                        className={` border-[1px] border-slate-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:border-[2px] focus:border-blue-500 w-full ease-linear transition-all duration-150
                        }`}
                        placeholder="Description"
                        // onChange={(e) => setShortDescription(e.target.value)}

                        onChange={(e) => {
                          if (e.target.value === " ") {
                            e.preventDefault();
                          } else {
                            setShortDescription(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded hover:shadow-lg border-[2px] focus:border-[2px] mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit" disabled={disable}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default AddSubscription;
