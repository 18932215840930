/*eslint-disable*/
import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import NotificationDropdown from "components/admin/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/admin/Dropdowns/UserDropdown.js";
import "./Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faCaretDown,
  faGear,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import { getMenu } from "services/enquiry.service";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [roleId, setRoleId] = useState(user.RoleId);
  let role = {
    id: roleId,
  };
  useEffect(() => {
    getMenu(role)
      .then((response) => {
        setMenu(response.data.Result);
      })
      .catch((error) => {
        console.error("Error fetching role data:", error);
      });
  }, []);

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-slate-300 flex flex-wrap items-center justify-between relative md:w-1/6 z-10 py-4 px-3">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            <img
              src="../images/header/AmarFinancelogo.png"
              className="m-auto"
            />
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>

          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none  absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Notus React
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <hr className="my-4 md:min-w-full" />

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {menu
                ?.filter((datalist) => datalist.parentId === null)
                .map((datalist, index) => {
                  return (
                    <li className="items-center" key={index}>
                      <Link
                        className={
                          "text-sm py-2 font-medium block " +
                          (window.location.href.indexOf(
                            "/admin/" + datalist.MenuURL
                          ) !== -1
                            ? "text-black hover:text-white"
                            : "text-black hover:text-white")
                        }
                        to={"/admin/" + datalist.MenuURL}
                      >
                        <i
                          className={
                            "fas fa-table mr-2 text-sm " +
                            (window.location.href.indexOf(
                              "/admin/enquirylist"
                            ) !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        {datalist.Name}
                      </Link>
                    </li>
                  );
                })}

              {/* <li className="items-center">
                <Link
                  className={
                    "text-sm py-2 font-medium block " +
                    (window.location.href.indexOf("/admin/enquirylist") !== -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/admin/enquirylist"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/enquirylist") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Enquiry
                </Link>
              </li> */}

              {/* <li className="items-center">
                <Link
                  className={
                    "text-sm py-2 font-medium block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-black")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-sm py-2 font-medium block " +
                    (window.location.href.indexOf("/admin/userlist") !== -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/admin/userlist"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/userlist") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  User Management
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-sm py-2 font-medium block " +
                    (window.location.href.indexOf("/admin/portfoliolist") !== -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/admin/portfoliolist"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/portfoliolist") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Portfolio
                </Link>
              </li> */}

              {/* <li className="items-center">
                <Link
                  className={
                    "text-sm py-2 font-medium block " +
                    (window.location.href.indexOf("/admin/careerlist") !== -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/admin/careerlist"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/careerlist") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Career
                </Link>
              </li> */}

              {/* <li className="items-center">
                <Link
                  className={
                    "text-sm py-2 font-medium block " +
                    (window.location.href.indexOf(
                      "/admin/configurationlist"
                    ) !== -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/admin/configurationlist"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/configurationlist"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Configuration
                </Link>
              </li>

              <li className="items-center">
                <div
                  className={
                    `text-sm py-2 font-medium block cursor-pointer ${
                      open ? " sidebar-item open " : "sidebar-item"
                    }` +
                    (window.location.href.indexOf("/admin/blogcategory") !== -1
                      ? " text-black hover:text-white"
                      : " text-black hover:text-white")
                  }
                >
                  <div
                    className="sidebar-title"
                    onClick={(e) => {
                      setOpen(!open);
                    }}
                  >
                    <span className="text-sm">
                      <i className={"fas fa-table mr-2 text-sm "}></i>
                      Blogs
                    </span>

                    <FontAwesomeIcon
                      icon={faCaretDown}
                      style={{ color: "#000000" }}
                      className="bi-chevron-down toggle-btn"
                    />
                  </div>
                  <div className="sidebar-content">
                    <li>
                      <Link to="/admin/blogcategory">
                        <FontAwesomeIcon
                          icon={faSitemap}
                          style={{ color: "#000000" }}
                          className="mr-1"
                        />
                        Blog Category
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/blog">
                        <FontAwesomeIcon
                          icon={faBookOpen}
                          style={{ color: "#000000" }}
                          className="mr-1"
                        />
                        Blogs
                      </Link>
                    </li>
                  </div>
                </div>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-2 font-medium block " +
                    (window.location.href.indexOf("/admin/application") !== -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/admin/application"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/application") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Applications
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-2 font-medium block " +
                    (window.location.href.indexOf("/admin/partnerlist") !== -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/admin/partnerlist"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/partnerlist") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Partner
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-2 font-medium block " +
                    (window.location.href.indexOf("/admin/testimoniallist") !==
                    -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/admin/testimoniallist"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/testimoniallist"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Testimonial
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-2 font-medium block " +
                    (window.location.href.indexOf("/admin/timeline") !== -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/admin/timeline"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/timeline") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Timeline
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-2 font-medium block " +
                    (window.location.href.indexOf("/admin/subscription") !== -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/admin/subscription"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/subscription") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Subscription
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-2 font-medium block " +
                    (window.location.href.indexOf("/admin/newsletter") !== -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/admin/newsletter"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/newsletter") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Newsletter
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
